import { useContext } from "react";
import { TestContext } from "../Context/TestContext";

const Footer = () => {
	const { isTest } = useContext(TestContext);
	return isTest ? (
		<></>
	) : (
		<footer class="page-footer">
			<div class="container">
				<div class="row">
					<div class="col l6 s12">
						<h5 class="white-text bold">The Forum Education</h5>
						<p class="grey-text text-lighten-4">
							Tổ chức giáo dục The Forum Education sở hữu hệ thống trung tâm
							giảng dạy IELTS, SAT, và tiếng Anh mọi độ tuổi. Hiện tại, The
							Forum đang có 5 chi nhánh khác nhau tại tỉnh Bà Rịa Vũng Tàu.
						</p>
					</div>
					<div class="col l4 offset-l2 s12">
						<h5 class="white-text bold">Contact</h5>
						<b>* Vũng Tàu</b>
						<ul>
							<li>
								<b>Hội Sở</b>: 01G3 Trung Tâm Thương Mại P7 TP Vũng Tàu
							</li>
							<li>
								<b>CS1</b>: 27G1 Nguyễn Oanh P7 TP Vũng Tàu
							</li>
							<li>
								<b>CS2</b>: 2K3-3K3 Nguyễn Thái Học P7 TP Vũng Tàu
							</li>
							<li>
								<b>CS3</b>: 75 Nguyễn Thái Học, P7, TP Vũng Tà
							</li>
						</ul>
						<b>* Bà Rịa</b>
						<ul>
							<li>
								<b>CS4</b>: 80 Bạch Đằng, P. Phước Trung, TP Bà Rịa
							</li>
							<li>
								<b>CS5</b>: 76 Bạch Đằng, P. Phước Trung, TP Bà Rịa
							</li>
						</ul>
						<b>Hotline</b>: 1900 886640 <br />
						Facebook:
						<a
							class="grey-text text-lighten-4"
							href="https://www.facebook.com/theforum.english"
						>
							The Forum Center
						</a>
					</div>
				</div>
			</div>
			<div class="footer-copyright">
				<div class="container">
					© 2021{" "}
					<a
						class="grey-text text-lighten-4"
						href="https://www.facebook.com/theforum.english"
					>
						The Forum Education
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
