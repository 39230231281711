import { useContext } from "react";
import { Link } from "react-router-dom";
import { TestContext } from "../Context/TestContext";

const Logo = () => {
	const { isTest } = useContext(TestContext);
	return isTest ? (
		<></>
	) : (
		<div>
			<ul className="logo">
				<li>
					<Link to="/">
						<img id="logo-1" height="72px" src="/static/LOGO.png" />
					</Link>
				</li>
				{/* <li><Link to="/"><img id='logo-2' width='115px' height='33.3px' src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/logo/BC.jpg" alt="" loading="lazy" /></Link></li>
                <li><Link to="/"><img id='logo-3' width='126.7px' height='66.7px' src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/logo/IELTS.png" alt="" loading="lazy" /></Link></li> */}
			</ul>
		</div>
	);
};

export default Logo;
