import React, { useContext, useState, useEffect } from 'react'
import firebase from 'firebase/app'
import "firebase/auth"
import { auth } from './firebase'

const AuthContext = React.createContext();

export function AuthProvider(props) {

    const [currentUser, setCurrentUser] = useState();

    const [loading, setLoading] = useState(true);

    async function signup(email, password) {
        // auth.sendSignInLinkToEmail(email, actionCodeSettings).then(() => {
        //     // The link was successfully sent. Inform the user.
        //     // Save the email locally so you don't need to ask the user for it again
        //     // if they open the link on the same device.
        //     console.log("EMAIL SENT")
        //     localStorage.setItem('emailForSignIn', email);

        await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(async () => {
            return auth.createUserWithEmailAndPassword(email, password).then((userCredential) => {
                return userCredential.user.sendEmailVerification({
                    url: "https://theforumcenter.web.app/",
                })
            })
        })
    }

    function updateProfile(name) {
        currentUser.updateProfile({
            displayName: name,
        })
    }


    function logout() {
        return auth.signOut()
    }

    async function login(email, password) {
        await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
            return auth.signInWithEmailAndPassword(email, password);
        }).catch(e => {
            return new Promise((resolve, reject) => {
                reject("Login failed")
            })
        })
    }

    function forgotPassword(email) {
        return auth.sendPasswordResetEmail(email);
    }

    useEffect(() => {
        const unsubcribe = auth.onAuthStateChanged(user => {
            setLoading(false)
            //console.log(user) //debug
            setCurrentUser(user);
        })
        return unsubcribe;
    }, [])



    const value = { currentUser, signup, login, updateProfile, logout, forgotPassword };

    return (
        <AuthContext.Provider value={value}>
            {!loading && props.children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext)
}