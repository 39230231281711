const Section2 = () => {
    return (

        < section >
            <div className="section2">
                <div className="container">
                    <div className="row">
                        <div className="col l6 m12 center">
                            <img loading="lazy" src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/slide1a.jpg" style={{ width: '100%' }}></img>
                        </div>
                        <div className="col l6 m12">
                            <h2>
                                <span className="bold cut">Tham gia 60+ buổi học tự do hàng tuần</span>
                            </h2>
                            <h3 className="bold"><span >Đặt ra  <span className="fill"> mục tiêu 9.0 </span> hoàn chỉnh ở kỹ năng <span className="fill"> Reading và Listening  </span>

                            </span>
                            </h3>
                            <p style={{ fontSize: '20px', fontStyle: 'italic' }}>Ngoài các tiết cố định, học sát theo giáo án được biên soạn sẵn, The Forum tổ chức lên tới 60+ buổi học tự do hàng tuần. Ở mỗi tiết học tự do, giáo viên sẽ hướng dẫn cách sử dụng các kỹ thuật giải bài, và thực hiện giải minh họa trọn vẹn một đề nào đó ở bốn kỹ năng.</p>


                        </div>
                    </div>
                </div>

            </div>

        </section >
    );
}

export default Section2;

