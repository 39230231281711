export const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw',
        height: '50vh',
        maxWidth: '500px',
        maxHeight: '300px',
        padding: '1em'
    }
};