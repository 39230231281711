import { FirestoreDocument } from "@react-firebase/firestore";
import React, {
	Fragment,
	memo,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
import SplitPane from "react-split-pane";
import { useAuth } from "../auth/AuthContext";
import { TestContext } from "../Context/TestContext";
import ButtonSubmit from "../helpers/btnSubmit";
import { compareMore, compareOne } from "../helpers/compareAnswer";
import useWindowDimensions from "../helpers/Dimension";
import Timed from "../helpers/timed";
import ReadingTestFooter from "../TestFooter/ReadingTestFooter";

const SplitPaneReading = ({ runMutation, id }) => {
	const formRef = useRef("");
	const spLeftRef = useRef("");
	const spRightRef = useRef("");
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const [mobile, setMobile] = useState({
		position: "vertical",
		resize: true,
		maxHeight: "88vh",
	});
	const { height, width } = useWindowDimensions();
	const { currentUser } = useAuth();
	const [key, setKey] = useState("");
	const [testname, setTestName] = useState("");
	const [loading, setLoading] = useState(false);
	const [leftPane, setLeftPane] = useState("");
	const { testInfo, setIsTest } = useContext(TestContext);
	const [rightPane, setRightPane] = useState("");

	async function handleSubmitDev(e) {
		// console.log(e)
		if (e) e.preventDefault();
		setLoading(true);
		let key_arr = JSON.parse(key);
		let current_question = 0;
		let user_answer = [];
		let user_evidence = [];
		var numCorrect = {
			numbercorrect: 0,
		};

		try {
			key_arr.forEach((value, idx) => {
				let current_answer = [value[0]];
				if (value[0] === 1) {
					current_question++;
					if (formRef.current.querySelector("#q-" + current_question).value)
						current_answer.push(
							formRef.current.querySelector("#q-" + current_question).value
						);
					else {
						let is_check = false;
						formRef.current
							.querySelector("#q-" + current_question)
							.querySelectorAll('[name="q-' + current_question + '"]')
							.forEach((ele) => {
								if (ele.checked) {
									current_answer.push(ele.value);
									is_check = true;
								}
							});
						if (!is_check) {
							current_answer.push("");
						}
					}
				} else {
					let temp_ans = "";
					let selector = "q";
					for (var q = 0; q < value[0]; q++) {
						current_question++;
						if (q === 0 || q === value[0] - 1)
							selector += "-" + current_question;
					}
					formRef.current
						.querySelector("#" + selector)
						.querySelectorAll('[name="' + selector + '"]')
						.forEach((ele) => {
							if (ele.checked) {
								temp_ans += ele.value;
							}
						});
					current_answer.push(temp_ans);
				}
				if (current_answer[0] === 1) {
					compareOne(value[1], current_answer, numCorrect);
				} else {
					compareMore(value[1], current_answer, numCorrect);
				}
				user_answer.push(current_answer);
			});

			document.querySelectorAll("textarea").forEach((t) => {
				user_evidence.push(t.value);
			});
		} catch (error) {
			window.alert(
				"Lỗi hệ thống, vui lòng liên hệ với nhân viên hoặc qua biểu tượng Messenger ở trang chủ để báo cáo lỗi."
			);
		}

		let is_unans = false;
		user_answer.forEach((ans) => {
			if (ans[1] === "") {
				is_unans = true;
			}
		});
		if (
			is_unans &&
			!window.confirm("There is unanswered question. Do you want to proceed?")
		) {
			setLoading(false);
			return;
		}

		let date = new Date();
		let elapsed = localStorage.getItem("start" + id)
			? Math.round(
					(date.getTime() - parseInt(localStorage.getItem("start" + id))) / 1000
			  )
			: 0;
		if (elapsed > 60 * 60) elapsed = 60 * 60;
		localStorage.removeItem("start" + id);
		localStorage.removeItem("date" + id);
		// console.log("Elapsed:", elapsed);
		// console.log("Correct:", numCorrect.numbercorrect)

		var meta = {
			type: "Reading",
			elapsed: elapsed,
			numbercorrect: numCorrect.numbercorrect,
			once: testInfo.once,
		};

		//DEV

		// console.log("Answer", user_answer)
		// console.log("Meta", meta)
		// console.log("Key", key_arr)

		//END DEV

		try {
			await runMutation({
				ClientTimestamp: Date.now(),
				Name: currentUser.displayName,
				Answer: JSON.stringify(user_answer),
				Meta: JSON.stringify(meta),
				Key: key,
				Testname: testname,
				Evidence: JSON.stringify(user_evidence),
			}).then((res) => {
				// console.log(res)
				history.replace("/done");
			});
		} catch (err) {
			window.alert(err);
			console.log(err);
		}
	}
	const btnSubmit = (e) => {
		return <ButtonSubmit handleSubmit={handleSubmitDev} />;
	};

	const checkWidth = () => {
		if (width < 768) {
			setMobile({
				direction: "horizontal",
				resize: false,
				maxHeight: "45vh",
			});
		} else {
			setMobile({
				direction: "vertical",
				resize: true,
				maxHeight: "91vh",
			});
		}
	};

	useEffect(() => {
		checkWidth();
		setIsTest(true);
		return () => setIsTest(false);
	}, []);

	useEffect(() => {
		checkWidth();
	}, [width]);

	useEffect(() => {
		if (rightPane) {
			document
				.querySelectorAll(".row .input-field")
				.forEach((t) => (t.style.display = "none"));
			//window.M.CharacterCounter.init(document.querySelectorAll('textarea'))
		}
	}, [rightPane]);

	return loading ? (
		<div className="row">
			<div className="col s8 offset-s2 alert ok">Loading...</div>
		</div>
	) : (
		<Fragment>
			{/* <WindowFocusHandler /> */}

			{!key && (
				<div className="row">
					<div className="col s8 offset-s2 alert ok">Loading...</div>
				</div>
			)}

			{!key && (
				<FirestoreDocument path={"/reading/" + id}>
					{(d) => {
						if (d.value && d.isLoading === false) {
							console.log("get-ed");
							setKey(d.value.key);
							setTestName(d.value.testname);
							setLeftPane(d.value.passage);
							setRightPane(d.value.question);
						}
					}}
				</FirestoreDocument>
			)}
			{leftPane && rightPane && key && (
				<form
					onSubmit={(e) => {
						e.preventDefault();
					}}
					ref={formRef}
				>
					<Timed
						btn={btnSubmit}
						submit={handleSubmitDev}
						isopen={isOpen}
						once={testInfo.once}
					/>
					<div className="reading-wrapper" id="highlight-wrapper-reading">
						<SplitPane
							className="sp-main"
							split={mobile.direction}
							allowResize={mobile.resize}
						>
							<div
								id="sp-left"
								minSize="15%"
								maxSize="85%"
								ref={spLeftRef}
								style={{ maxHeight: mobile.maxHeight }}
							>
								{leftPane && ReactHtmlParser(leftPane)}
							</div>

							<div
								id="sp-right"
								minSize="15%"
								maxSize="85%"
								ref={spRightRef}
								style={{ maxHeight: mobile.maxHeight }}
							>
								{rightPane && ReactHtmlParser(rightPane)}
							</div>
						</SplitPane>
					</div>

					{
						<ReadingTestFooter
							btnSubmit={btnSubmit}
							spLeftRef={spLeftRef}
							spRightRef={spRightRef}
							setIsOpen={setIsOpen}
							id={id}
							width={width}
						/>
					}
				</form>
			)}
		</Fragment>
	);
};

export default memo(SplitPaneReading);
