import fb from '../auth/firebase'
import firebase from "firebase/app"
import 'firebase/firestore'

import { FirestoreProvider } from "@react-firebase/firestore";


const FSProvider = ({ children }) => {

    return (
        <FirestoreProvider {...fb} firebase={firebase}>
            {children}
        </FirestoreProvider>
    );
}
export default FSProvider;