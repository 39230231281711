// import { Button, Form , Spinner } from 'react-bootstrap';

import { useMemo, useState } from "react";

export const refreshSelectCSS = () => {
	window.M.FormSelect.init(document.querySelectorAll("select"));
};

const GenResult = ({ fetchAPI, getResult, loading }) => {
	const [testType, setTestType] = useState("");
	const [testID, setTestID] = useState("");
	const [testName, setTestName] = useState("");
	const [testIDList, setTestIDList] = useState([]);
	const [preventThorttle, setPreventThorttle] = useState(false);

	const changeType = (e) => {
		setTestType(e.target.value);
		setTestID("");
		setPreventThorttle(true);
		fetchAPI(`/test/ids?type=${e.target.value}`).then((res) => {
			setTestIDList(res.data);
			refreshSelectCSS();
		});
	};

	const changeID = (e) => {
		setTestID(e.target.value);
		setTestName(e.target.options[e.target.selectedIndex].text);
		setPreventThorttle(false);
		refreshSelectCSS();
	};

	return (
		<div style={{ padding: "30px" }}>
			<h2 className="center">Kiểm tra kết quả TEST</h2>
			<form onSubmit={(e) => getResult(e, testType, testID, testName)}>
				<div className="mb-3 input-field">
					<input type="email" className="form-control" id="emailInput" />
					<label for="emailInput">Email address</label>
					<div id="emailHelp" className="form-text mb-5">
						Nhập đầy đủ email của học viên. Ví dụ: thanhdanh123@gmail.com
					</div>
					<div className="mb-3 input-field">
						<select
							id="type-input"
							aria-label="Chọn bài"
							className="d-none"
							defaultValue=""
							onChange={changeType}
							disabled={preventThorttle}
						>
							<option value="" disabled>
								Chọn loại bài
							</option>
							<option value="placement">Placement Test</option>
							<option value="reading">Reading</option>
							<option value="listening">Listening</option>
							<option value="writing">Writing</option>
						</select>
						<select
							id="type-input"
							aria-label="Chọn bài"
							className="d-none"
							defaultValue=""
							onChange={changeID}
						>
							<option value="" disabled>
								Chọn ID
							</option>
							{testIDList.length > 0 &&
								testIDList
									.slice(0)
									.reverse()
									.map((testID) => {
										return (
											<option value={testID.id} data-name={testID.testname}>
												{testID.testname}
											</option>
										);
									})}
						</select>
						<label>Thông tin bài test</label>
					</div>
				</div>

				<div className="d-flex justify-content-around align-item-center">
					<div className="d-flex w-50 align-item-center">
						<button
							type="submit"
							className="waves-effect waves-light btn m-10-full p-lr-full-5 main-theme-back"
							disabled={loading || !(testID && testType)}
						>
							Lấy kết quả
						</button>

						{loading && (
							<div className="preloader-wrapper small active">
								<div class="spinner-layer spinner-red">
									<div class="circle-clipper left">
										<div class="circle"></div>
									</div>
									<div class="gap-patch">
										<div class="circle"></div>
									</div>
									<div class="circle-clipper right">
										<div class="circle"></div>
									</div>
								</div>
							</div>
						)}
					</div>

					<button
						type="reset"
						className="waves-effect waves-light btn m-10-full p-lr-full-5 main-theme-back"
						onClick={(e) => {
							document.getElementById("placement-result").style.display =
								"none";
						}}
					>
						Clear
					</button>
				</div>
			</form>
		</div>
	);
};

export default GenResult;
