import { useCallback, useState } from 'react';

const AddStaff = ({ fetchAPI }) => {

    const [message, setMessage] = useState('')
    const [messageOK, setMessageOK] = useState('')
    const [loading, setLoading] = useState(false);

    const gen = useCallback(async (e) => {
        e.preventDefault();
        setMessage('')
        setMessageOK('')
        var t = document.getElementById("gen-result");
        t.innerHTML = "";
        if (e.target[0].value.search("@") !== -1) {
            setLoading(true);
            const emailFetch = await fetchAPI('/staff/add-hocvu?email=' + e.target[0].value);
            if (emailFetch.data.message) {
                setMessage(emailFetch.data.message)
                setLoading(false)
                return;
            }
            console.log(emailFetch.data)
            setMessageOK("Thêm nhân viên thành công")

        }
        else setMessage("Email không hợp lệ")
        setLoading(false);
    }, []);

    return (
        <div style={{ padding: '30px' }}>
            <h2 className="center">Thêm nhân viên</h2>
            <form onSubmit={gen}>
                <div className="mb-3 input-field">
                    <input type="email" className="form-control" id="emailInput" />
                    <label for="emailInput">Email address</label>
                    <div id="emailHelp" className="form-text mb-5">Viết đầy đủ. Ví dụ: thanhdanh123@gmail.com</div>
                </div>
                <div className="d-flex justify-content-around">
                    <div className="d-flex w-50 align-item-center">
                        <button type="submit" className="waves-effect waves-light btn m-10-full p-lr-full-5 main-theme-back">Thêm
                        </button>

                        {
                            loading &&
                            <div className="preloader-wrapper small active">
                                <div class="spinner-layer spinner-red">
                                    <div class="circle-clipper left">
                                        <div class="circle"></div>
                                    </div><div class="gap-patch">
                                        <div class="circle"></div>
                                    </div><div class="circle-clipper right">
                                        <div class="circle"></div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <button type="reset" className="waves-effect waves-light btn m-10-full p-lr-full-5 main-theme-back" onClick={() => { setMessage(''); setMessageOK('') }}>Clear</button>
                </div>
                {message && <div className="alert">
                    {message}
                </div>}
                {messageOK && <div className="alert ok">
                    {messageOK}
                </div>}
            </form>
        </div>
    )
}

const DeleteStaff = ({ fetchAPI }) => {

    const [message, setMessage] = useState('')
    const [messageOK, setMessageOK] = useState('')
    const [loading, setLoading] = useState(false);

    const gen = useCallback(async (e) => {
        e.preventDefault();
        setMessage('')
        setMessageOK('')
        var t = document.getElementById("gen-result");
        t.innerHTML = "";
        if (e.target[0].value.search("@") !== -1) {
            setLoading(true);
            const emailFetch = await fetchAPI('/staff/delete-hocvu?email=' + e.target[0].value);
            if (emailFetch.data.message) {
                setMessage(emailFetch.data.message)
                setLoading(false)
                return;
            }
            console.log(emailFetch.data)
            setMessageOK("Xoá nhân viên thành công")

        }
        else setMessage("Email không hợp lệ")
        setLoading(false);
    }, []);

    return (
        <div style={{ padding: '30px' }}>
            <h2 className="center">Xoá nhân viên</h2>
            <form onSubmit={gen}>
                <div className="mb-3 input-field">
                    <input type="email" className="form-control" id="emailInput" />
                    <label for="emailInput">Email address</label>
                    <div id="emailHelp" className="form-text mb-5">Viết đầy đủ. Ví dụ: thanhdanh123@gmail.com</div>
                </div>
                <div className="d-flex justify-content-around">
                    <div className="d-flex w-50 align-item-center">
                        <button type="submit" className="waves-effect waves-light btn m-10-full p-lr-full-5 main-theme-back">Xoá
                        </button>

                        {
                            loading &&
                            <div className="preloader-wrapper small active">
                                <div class="spinner-layer spinner-red">
                                    <div class="circle-clipper left">
                                        <div class="circle"></div>
                                    </div><div class="gap-patch">
                                        <div class="circle"></div>
                                    </div><div class="circle-clipper right">
                                        <div class="circle"></div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <button type="reset" className="waves-effect waves-light btn m-10-full p-lr-full-5 main-theme-back" onClick={() => { setMessage(''); setMessageOK('') }}>Clear</button>
                </div>
                {message && <div className="alert">
                    {message}
                </div>}
                {messageOK && <div className="alert ok">
                    {messageOK}
                </div>}
            </form>
        </div>
    )
}

const SuperAdmin = ({ fetchAPI }) => {
    return (
        <>
            <AddStaff fetchAPI={fetchAPI} />
            <DeleteStaff fetchAPI={fetchAPI} />
        </>

    );
}


export default SuperAdmin;