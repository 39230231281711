import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import { TestContext } from "../Context/TestContext";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";

const Navbar = () => {
	useEffect(() => {
		window.addEventListener("scroll", function () {
			if (window.scrollY > 250) {
				document.getElementById("navbar_top") &&
					document.getElementById("navbar_top").classList.add("nav-fixed");
			} else {
				document.getElementById("navbar_top") &&
					document.getElementById("navbar_top").classList.remove("nav-fixed");
			}
		});
	}, []);

	const { currentUser } = useAuth();
	const { isTest } = useContext(TestContext);
	useEffect(() => {
		if (window.M) window.M.AutoInit(); //init lan dau
	}, []);
	useEffect(() => {
		if (isTest == false) window.M.AutoInit(); //mo lai nav phai init lai, matetialize sucks
	}, [isTest]);

	return isTest ? (
		<></>
	) : (
		<nav className="nav-wrapper" id="navbar_top">
			<div className="container">
				<a href="#" data-target="mobile-demo" class="sidenav-trigger">
					<i class="material-icons">menu</i>
				</a>
				<ul className="left hide-on-med-and-down">
					{currentUser && <Link to="/take-test">Take test</Link>}
				</ul>
				<ul className="left hide-on-med-and-down">
					{currentUser && <Link to="/my-test">My test</Link>}
				</ul>
				<ul className="left hide-on-med-and-down">
					{currentUser && <Link to="/join">My class</Link>}
				</ul>
				{currentUser && <SignedInLinks />}
				{!currentUser && <SignedOutLinks />}
			</div>

			<ul class="sidenav" id="mobile-demo">
				<ul className="line-break" />
				<li>{currentUser && <Link to="/take-test">Take test</Link>}</li>
				<li>{currentUser && <Link to="/my-test">My test</Link>}</li>
				<li>{currentUser && <Link to="/join">My class</Link>}</li>
			</ul>
		</nav>
	);
};

export default Navbar;
