import { useEffect } from "react";
import { Link } from "react-router-dom"
import { useAuth } from "../../auth/AuthContext";
import { fs } from "../../auth/firebase";

const JoinRequest = () => {

    const { currentUser } = useAuth();

    useEffect(() => {
        var elems = document.querySelectorAll('.collapsible');
        var instances = window.M.Collapsible.init(elems);
        // fs.collection('class-request').
    }, [])

    return (
        <div className="container">
            <h2>Requests</h2>
            <table className="w-fit">
                <thead>
                    <tr>
                        <th>Class</th>
                        <th>Name</th>
                        <th>Date request</th>
                        <th>Confirm</th>
                    </tr>
                </thead>

                <tbody>
                    <tr key="1">
                        <td>Alvin</td>
                        <td>Eclair</td>
                        <td>$0.87</td>
                        <td>
                            <button value="1" className="btn waves-effect green accent-4 mr-3" onClick={(e) => console.log(e)}> Accept</button>
                            <button className="btn waves-effect main-theme-back"> Reject</button>
                        </td>
                    </tr>
                    <tr>
                        <td>Alan</td>
                        <td>Jellybean</td>
                        <td>$3.76</td>
                        <td>
                            <button className="btn waves-effect green accent-4 mr-3"> Accept</button>
                            <button className="btn waves-effect main-theme-back"> Reject</button>
                        </td>
                    </tr>
                    <tr>
                        <td>Jonathan</td>
                        <td>Lollipop</td>
                        <td>$7.00</td>
                        <td>
                            <button className="btn waves-effect green accent-4 mr-3"> Accept</button>
                            <button className="btn waves-effect main-theme-back"> Reject</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


    );

}

export default JoinRequest;