import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import { TestContext } from "../Context/TestContext";
import { modalStyles } from "../styles/styles";

// User has switched back to the tab

Modal.setAppElement("#root");

const TestFound = (props) => {
	// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
	var subtitle;
	const [meta, setMeta] = useState("");
	const { testInfo, setTestInfo, setIsTest } = useContext(TestContext);
	const afterOpenModal = () => {
		// references are now sync'd and can be accessed.
		subtitle.style.color = "#f00";
	};
	const closeModal = () => {
		setIsTest(false);
		props.isopen(false);
	};
	const ready = () => {
		props.history.push("/" + meta.type.toLowerCase() + "/" + props.id);
	};

	useEffect(() => {
		setMeta(props.meta);
		setTestInfo({
			time:
				props.meta.type === "Reading"
					? 20 * props.meta.num_of_pass
					: props.meta.type === "Writing"
					? 60
					: 40,
			id: props.idDecrypt,
			once: testInfo.once,
		});
	}, [props.isopen]);

	return (
		<div style={{ maxWidth: "100px" }}>
			<Modal
				isOpen={true}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={modalStyles}
				overlayClassName="Overlay"
			>
				<h3 ref={(_subtitle) => (subtitle = _subtitle)} className="bold center">
					{meta.type} Test
				</h3>
				<div>
					{" "}
					Test name: <span className="bold">{meta.testname}</span>
				</div>
				{meta.num_of_pass && (
					<div>
						{" "}
						Number of passage(s):{" "}
						<span className="bold">{meta.num_of_pass}</span>
					</div>
				)}
				<div>
					Duration:{" "}
					<span className="bold">
						{testInfo ? testInfo.time : "./."} minutes
					</span>
				</div>
				<div className="center" style={{ marginTop: "40px" }}>
					<button className="btn bold main-theme-back" onClick={ready}>
						<i className="material-icons left" style={{ lineHeight: "38px" }}>
							border_color
						</i>
						Ready
					</button>
				</div>
			</Modal>
		</div>
	);
};

export default TestFound;
