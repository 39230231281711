import { useAuth } from "./AuthContext";
import { useHistory } from "react-router-dom";
import { useState } from "react"

const Logout = () => {
    const history = useHistory();
    const { logout } = useAuth();
    const [error, setError] = useState('');
    async function LogOut() {
        try {
            await logout();
            history.push('/')
        } catch (error) {
            setError("Cannot log out:" + error);
        }
    }

    LogOut();

    return (
        <>
            {error && <div></div>}
        </>
    );
}

export default Logout;