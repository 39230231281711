const SectionBottom = () => {
    return (
        <section>
            <div className="section-bottom"> <div style={{
                backgroundImage: 'url(https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/slidebottom.jpg)',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                backgroundSize: '90%',
                backgroundPosition: 'center',
            }}></div>
                <div style={{ padding: '3em'}}>
                    <h2 style={{ fontWeight: 'bold' }}>Chương trình học <span className="cut">Online Hybrid Fusion™</span> chất lượng nhất cùng dàn giáo viên 8.5 IELTS</h2>
                    <p style={{ fontSize: '20px', fontStyle: 'italic' }}>
                        Vốn dĩ việc học online là một trong những ác mộng của không ít các bạn trẻ vì chất lượng kết nối thiếu ổn định và độ tương tác thấp. Tuy nhiên, The Forum Center là trung tâm đầu tiên ứng dụng nền tảng dạy Online tốt nhất thế giới đươc cung cấp bởi Google, cam kết 100% trải nghiệm như học thật ở ngoài.</p>
                </div>
            </div>

        </section>
    );
}

export default SectionBottom;