import { useAuth } from "../auth/AuthContext";
import ListeningPane from "./ListeningPane";
import { Redirect } from 'react-router-dom';
import { FirestoreMutation } from "@react-firebase/firestore";
import { useContext } from "react";
import { TestContext } from "../Context/TestContext";

const Listening = (props) => {
    const { currentUser } = useAuth();
    const { testInfo } = useContext(TestContext);
    return testInfo ? (
            <FirestoreMutation type="set" path={"/submit/" + currentUser.uid + '/listening/' + testInfo.id}>
                {({ runMutation }) => {
                    return currentUser ? (
                        <ListeningPane runMutation={runMutation} id={testInfo.id} />
                    ) : (<Redirect to="/" />);
                }}
            </FirestoreMutation>
    ): (<Redirect to="/" />);
}

export default Listening;


