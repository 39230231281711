
import { useEffect, useState } from 'react';
/**
 * use for Reading and listening
 */


const ResultTable = ({ response, showKey }) => {

    const [key, setKey] = useState();
    const [answer, setAnswer] = useState();
    const [evidence, setEvidence] = useState();
    var currentQuestion = 0;

    useEffect(() => {
        setKey(response[0].key)
        setAnswer(response[1].answer)
        setEvidence(response[1].evidence)
    }, [])

    useEffect(() => {
        if (response[0]) {
            setKey(response[0].key)
            setAnswer(response[1].answer)
            setEvidence(response[1].evidence)
        }
    }, [response])

    return (
        <table className="result-table striped bordered" style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th className="center">Question</th>
                    {showKey && <th>Key</th>}
                    <th>Student's answer</th>
                    <th className="center">Correct</th>
                    <th className="evidence">Evidence</th>
                </tr>
            </thead>
            <tbody>


                {key && key.map((k, idx) => {
                    currentQuestion += k[0];
                    return (
                        <tr key={idx}>
                            <td className="center">
                                {
                                    (k[0] === 1) ? currentQuestion : currentQuestion - k[0] + 1 + '-' + currentQuestion
                                }
                            </td>

                            {showKey && <td>{k[1].join(' / ')}</td>}
                            <td>
                                {(answer[idx][0] > 1) ? (answer[idx][1] ? ([...answer[idx][1]].join()) : <span className="italic orange-text">omitted</span> ): (answer[idx][1] ? answer[idx][1] : <span className="italic orange-text">omitted</span>)}
                            </td>
                            <td className="center">
                                <em className={(answer[idx][2] === 0) ? "false-answer" : "correct-answer"}></em>
                                {answer[idx][0] > 1 && <span><br />(Correct {answer[idx][2]}/{answer[idx][0]})</span>}
                            </td>

                            <td className="evidence">
                                {evidence && evidence[idx]}
                            </td>


                        </tr>

                    )
                })}



            </tbody>
        </table>
    );
}

export default ResultTable;