import CryptoJS from "crypto-js";
import { useCallback, useEffect, useState } from "react";
import { refreshSelectCSS } from "./genResult";

const GenId = ({ fetchAPI }) => {
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const [testIDList, setTestIDList] = useState([]);
	const [testID, setTestID] = useState([]);

	useEffect(() => {
		fetchAPI(`/test/ids?type=placement`).then((res) => {
			setTestIDList(res.data);
			refreshSelectCSS();
		});
	}, []);

	const copy = useCallback((id) => {
		var copyText = document.getElementById(id);
		var text = copyText.innerText.substr(copyText.innerText.indexOf("U"));
		navigator.clipboard.writeText(text).then(
			function () {
				window.M.toast({
					html: "Copy thành công",
					classes: "main-theme-back",
					inDuration: 10000,
				});
			},
			function (err) {
				window.M.toast({ html: "Lỗi:" + err, classes: "main-theme-back" });
			}
		);
	}, []);

	const gen = useCallback(async (e, testID) => {
		e.preventDefault();
		setMessage("");
		var t = document.getElementById("gen-result");
		t.innerHTML = "";
		e.target[0].value = e.target[0].value.toLowerCase();
		if (e.target[0].value.search("@") !== -1)
			switch (e.target[2].value) {
				case "":
					setMessage("Vui lòng chọn tên bài test");
					return;
				default:
					setLoading(true);
					const emailFetch = await fetchAPI(
						"/students/find-email?email=" + e.target[0].value
					);
					if (emailFetch.data.message) {
						setMessage(emailFetch.data.message);
						setLoading(false);
						return;
					}
					console.log(emailFetch.data.uid);
					let n = document.createElement("ol");
					n.className = "list-group list-group-numbered id-list-group";
					let l2 = document.createElement("li");
					l2.className = "list-group-item d-flex";
					let a2 = document.createElement("h5");
					a2.className = "w-100";
					let split = emailFetch.data.displayName.split(":");
					a2.innerHTML =
						"Học viên: <span class='bold'>" +
						split[0] +
						"</span><br />" +
						"SĐT: <span class='bold'>" +
						split[1] +
						"</span>";
					l2.appendChild(a2);
					n.appendChild(l2);

					let l = document.createElement("li");
					l.className =
						"list-group-item d-flex justify-content-between align-items-start";
					let a = document.createElement("div");
					a.className = "w-100";
					a.id = "lis-id";

					a.innerText = CryptoJS.AES.encrypt(
						`placement-lis${testID !== "placement-1" ? "-" + testID : ""}`,
						e.target[0].value
					).toString();

					let childBtn = document.createElement("btn");
					childBtn.className =
						"btn waves-effect waves-light m-1 main-theme-back";
					childBtn.onclick = function () {
						copy(a.id);
					};
					childBtn.innerText = "copy";
					a.prepend(childBtn);

					let i = document.createElement("div");
					i.className = "bold";
					i.innerText = "Listening";
					a.prepend(i);
					l.appendChild(a);
					n.appendChild(l);

					let r = document.createElement("li");
					r.className =
						"list-group-item d-flex justify-content-between align-items-start";
					let m = document.createElement("div");
					m.className = "w-100";
					m.id = "read-id";
					m.innerText = CryptoJS.AES.encrypt(
						`placement-read${testID !== "placement-1" ? "-" + testID : ""}`,
						e.target[0].value
					).toString();

					childBtn = document.createElement("btn");
					childBtn.className =
						"btn waves-effect waves-light m-1 main-theme-back";
					childBtn.onclick = function () {
						copy(m.id);
					};
					childBtn.innerText = "copy";
					m.prepend(childBtn);

					let d = document.createElement("div");
					d.className = "bold";
					d.innerText = "Reading";
					m.prepend(d);
					r.appendChild(m);
					n.appendChild(r);

					let c = document.createElement("li");
					c.className =
						"list-group-item d-flex justify-content-between align-items-start";
					let s = document.createElement("div");
					s.className = "w-100";
					s.id = "write-id";
					s.innerText = CryptoJS.AES.encrypt(
						`placement-write${testID !== "placement-1" ? "-" + testID : ""}`,
						e.target[0].value
					).toString();

					childBtn = document.createElement("btn");
					childBtn.className =
						"btn waves-effect waves-light m-1 main-theme-back";
					childBtn.onclick = function () {
						copy(s.id);
					};
					childBtn.innerText = "copy";
					s.prepend(childBtn);

					let p = document.createElement("div");
					p.className = "bold";
					p.innerText = "Writing";
					s.prepend(p);
					c.appendChild(s);
					n.appendChild(c);
					t.appendChild(n);
					break;
			}
		else setMessage("Email không hợp lệ");
		setLoading(false);
	}, []);

	return (
		<div style={{ padding: "30px" }}>
			<h2 className="center">Test ID Generator</h2>
			<form onSubmit={(e) => gen(e, testID)}>
				<div className="mb-3 input-field">
					<input type="email" className="form-control" id="emailInput" />
					<label for="emailInput">Email address</label>
					<div id="emailHelp" className="form-text mb-5">
						Viết đầy đủ. Ví dụ: thanhdanh123@gmail.com
					</div>
				</div>
				<div className="mb-3 input-field">
					<select
						aria-label="Chọn bài"
						className="d-none"
						defaultValue=""
						onChange={(e) => {
							setTestID(e.target.value);
						}}
					>
						<option value="" disabled>
							Chọn bài test
						</option>
						{testIDList
							.slice(0)
							.reverse()
							.map((testID) => {
								return (
									<option value={testID.id} data-name={testID.testname}>
										{testID.testname}
									</option>
								);
							})}
					</select>
					<label>Tên bài test</label>
				</div>
				<div className="d-flex justify-content-around">
					<div className="d-flex w-50 align-item-center">
						<button
							type="submit"
							className="waves-effect waves-light btn m-10-full p-lr-full-5 main-theme-back"
						>
							Tạo ID
						</button>

						{loading && (
							<div className="preloader-wrapper small active">
								<div class="spinner-layer spinner-red">
									<div class="circle-clipper left">
										<div class="circle"></div>
									</div>
									<div class="gap-patch">
										<div class="circle"></div>
									</div>
									<div class="circle-clipper right">
										<div class="circle"></div>
									</div>
								</div>
							</div>
						)}
					</div>

					<button
						type="reset"
						className="waves-effect waves-light btn m-10-full p-lr-full-5 main-theme-back"
						onClick={() => {
							document.getElementById("gen-result").innerHTML = "";
							setMessage("");
						}}
					>
						Clear
					</button>
				</div>
				{message && <div className="alert">{message}</div>}
			</form>
		</div>
	);
};

//function copy(e) {
/* Get the text field */
//console.log(e)
// var copyText = document.getElementById("myInput");

// /* Select the text field */
// copyText.select();
// copyText.setSelectionRange(0, 99999); /* For mobile devices */

// /* Copy the text inside the text field */
// document.execCommand("copy");

// /* Alert the copied text */
// alert("Copied the text: " + copyText.value);
//}

export default GenId;
