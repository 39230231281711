import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "./AuthContext";
import { Link, useHistory } from "react-router-dom";
import fb, { uiConfig } from "./firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { motion } from "framer-motion";
import { pageTransition, pageVariant } from "../styles/pageTransition";

export const SignUpThirdParty = () => {
	const history = useHistory();
	const patterns = {
		number: /^\d{10,11}$/,
	};

	const { currentUser, updateProfile } = useAuth();
	const numberRef = useRef();
	async function updatePath() {
		await fb
			.firestore()
			.collection("submit")
			.doc(currentUser.uid)
			.set({ fix: true });
	}
	const [error, setError] = useState("");

	async function handleSubmit(e) {
		e.preventDefault();
		if (!patterns.number.test(numberRef.current.value)) {
			return setError("Invalid Phone number (10-11 number)");
		}

		await updateProfile(
			currentUser.displayName + ":" + numberRef.current.value
		);

		history.push("/");
	}
	useEffect(() => {
		if (currentUser) {
			let split = currentUser.displayName.split(":");
			if (split[1]) {
				history.push("/");
			} else {
				updatePath();
			}
		}
	}, [currentUser]);

	return currentUser ? (
		<div className="container signup-container">
			<div className="row signup" style={{ maxWidth: "500px" }}>
				<form className="col s12" onSubmit={handleSubmit}>
					<div className="row center">
						<h4>Please complete the sign-up</h4>
					</div>
					{error && <div className="alert">{error} </div>}
					<div className="row signup">
						<div className="input-field col s12">
							<i className="material-icons prefix">phone</i>
							<input
								id="number"
								type="tel"
								ref={numberRef}
								required
								className="validate"
							/>
							<label htmlFor="number">Phone number</label>
						</div>
					</div>
					<button className="btn signup">Complete</button>
				</form>
			</div>
		</div>
	) : (
		<></>
	);
};

export default function Signup() {
	const emailRef = useRef();
	const passwordRef = useRef();
	const passwordConfirmRef = useRef();
	const fullNameRef = useRef();
	const patterns = {
		fullname: /^[^!@#^&*()./\\[\]<>%${},0123456789\-=+?:]+$/,
		number: /^\d{10,11}$/,
		password: /^.{8,}$/,
		email: /^[^@]+@[^@]+\.[^@]+$/,
	};

	const numberRef = useRef();
	const buttonRef = useRef();
	const { signup, currentUser, updateProfile, logout } = useAuth();
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);

	async function handleSubmit(e) {
		e.preventDefault();
		if (passwordConfirmRef.current.value !== passwordRef.current.value) {
			return setError("Passwords do not match");
		}

		if (!patterns.fullname.test(fullNameRef.current.value)) {
			return setError("Invalid name");
		}
		if (!patterns.number.test(numberRef.current.value)) {
			return setError("Invalid Phone number (10-11 number)");
		}
		if (!patterns.email.test(emailRef.current.value)) {
			return setError("Check your email again.");
		}
		if (!patterns.password.test(passwordRef.current.value)) {
			return setError("Passwords must be at least 8 characters");
		}

		setError("");
		setLoading(true);
		fullNameRef.current.disabled = true;
		emailRef.current.disabled = true;
		passwordConfirmRef.current.disabled = true;
		passwordRef.current.disabled = true;
		numberRef.current.disabled = true;
		buttonRef.current.disabled = true;
		await signup(emailRef.current.value, passwordRef.current.value).catch(
			(error) => {
				setError(error.message);
				fullNameRef.current.disabled = false;
				emailRef.current.disabled = false;
				passwordConfirmRef.current.disabled = false;
				passwordRef.current.disabled = false;
				numberRef.current.disabled = false;
				buttonRef.current.disabled = false;
			}
		);
		setLoading(false);
	}
	async function updateName() {
		try {
			await fb
				.firestore()
				.collection("submit")
				.doc(currentUser.uid)
				.set({ fix: true });
			await updateProfile(
				fullNameRef.current.value + ":" + numberRef.current.value
			);
			setMessage(
				"Create successfully, please check your email for further verification."
			);
			logout();
		} catch {
			setError("Failed to create an account");
		}
	}

	useEffect(() => {
		if (currentUser) {
			if (currentUser.emailVerified) return;
			updateName();
			setLoading(false);
		}
	}, [currentUser]);

	return (
		<motion.div
			initial="out"
			animate="in"
			exit="out"
			variants={pageVariant}
			transition={pageTransition}
		>
			<div className="container signup-container">
				<div className="row signup signup-wrapper">
					<form className="col l6 s12" onSubmit={handleSubmit}>
						<div className="row center">
							<h4>Sign up</h4>
						</div>
						{error && <div className="alert">{error} </div>}
						{message && <div className="alert ok">{message} </div>}

						<div className="row signup">
							<div className="input-field col s12">
								<i className="material-icons prefix">account_circle</i>
								<input
									id="first_name"
									type="text"
									ref={fullNameRef}
									required
									className="validate"
								/>
								<label htmlFor="first_name">Full Name</label>
							</div>
							<div className="input-field col s12">
								<i className="material-icons prefix">phone</i>
								<input
									id="number"
									type="tel"
									ref={numberRef}
									required
									className="validate"
								/>
								<label htmlFor="number">Phone number</label>
							</div>
						</div>
						<div className="row signup">
							<div className="input-field col s12">
								<i className="material-icons prefix">email</i>
								<input
									id="email"
									type="email"
									ref={emailRef}
									required
									className="validate"
								/>
								<label htmlFor="email">Email</label>
							</div>
						</div>
						<div className="row signup">
							<div className="input-field col s12">
								<i className="material-icons prefix">vpn_key</i>
								<input
									id="password"
									type="password"
									ref={passwordRef}
									required
									className="validate"
								/>
								<label htmlFor="password">Password</label>
							</div>
						</div>
						<div className="row signup">
							<div className="input-field col s12">
								<i className="material-icons prefix">done</i>
								<input
									id="password-confirm"
									type="password"
									ref={passwordConfirmRef}
									required
									className="validate"
								/>
								<label htmlFor="password-confirm">Password confirm</label>
							</div>
						</div>
						<div className="row signup">
							<ul>
								<li>
									<button className="btn signup" ref={buttonRef}>
										Sign up
									</button>
									{loading && (
										<div style={{ marginTop: "1em", textAlignLast: "center" }}>
											<div className="preloader-wrapper active">
												<div className="spinner-layer spinner-red-only">
													<div className="circle-clipper left">
														<div className="circle"></div>
													</div>
													<div className="gap-patch">
														<div className="circle"></div>
													</div>
													<div className="circle-clipper right">
														<div className="circle"></div>
													</div>
												</div>
											</div>
										</div>
									)}
								</li>
							</ul>
							<div className="signup prompt center">
								Already have an account?{" "}
								<Link style={{ fontStyle: "italic" }} to="/log-in">
									Log In
								</Link>
							</div>
						</div>
					</form>

					<div className="form-border-right col l1 hide-on-med-and-down"></div>
					<div className="col l5 s12">
						<StyledFirebaseAuth
							className="mt-5"
							uiConfig={uiConfig}
							firebaseAuth={fb.auth()}
						/>
					</div>
				</div>
			</div>
			<ul className="line-break"></ul>
		</motion.div>
	);
}
