import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const fb = firebase.initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESS_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

var provider = new firebase.auth.GoogleAuthProvider();
var provider3 = new firebase.auth.FacebookAuthProvider();

export const uiConfig = {
	signInFlow: "popup",
	signInSuccessUrl: "/signupthirdparty",
	signInOptions: [provider.providerId, provider3.providerId],
};

export const auth = fb.auth();
export const fs = fb.firestore();

export default fb;
