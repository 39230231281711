export const compareOne = (arrKey, arrAns, numCorrect) => {
    let not_correct = true;
    if (arrAns[1] !== undefined) {
        arrKey.forEach((k) => {
            if (arrAns[1].trim().toUpperCase() === k.trim().toUpperCase()) {
                arrAns.push(1);
                not_correct = false;
                numCorrect.numbercorrect++;
            }
        })
    }
    if (not_correct)
        arrAns.push(0);

}

export const compareMore = (arrKey, arrAns, numCorrect) => {
    let num_to_push = 0;
    let sorted_key = arrKey[0].split(',').sort();
    if ([...arrAns[1]].length > sorted_key.length) {
        arrAns.push(0);
        return;
    }
    [...arrAns[1]].forEach((ans) => {
        sorted_key.forEach((k) => {
            if (ans === k)
                num_to_push++
        })
    })
    arrAns.push(num_to_push);
    numCorrect.numbercorrect += num_to_push;

}