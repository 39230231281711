import { useCallback, useEffect } from "react"
import axios from "axios"
import JoinClass from "./JoinClass";
import StudentClass from "./StudentClass";
const MyClass = () => {

    const client = axios.create({
        baseURL: "localhost:5000/"
    });

    const fetchAPI = useCallback(
        (type) => {
            return client.get(type)
        },
        [],
    )

    useEffect(() => {
        var elems = document.querySelectorAll('.scrollspy');
        var instances = window.M.ScrollSpy.init(elems);
    }, [])
    return (
			<div className="row">
				<div className="col hide-on-small-only m3 l2">
					<ul className="section table-of-contents pinned">
						<li>
							<a href="#join-class">Join new class</a>
						</li>
						<li>
							<a href="#my-className">My class</a>
						</li>
					</ul>
				</div>

				<div className="mt-5 col s12 m9 l10 h-100">
					<h4>Sorry this page is under maintainance</h4>
				</div>

				{/* <div className="col s12 m9 l10 h-100">
                <div id="join-class" className="section scrollspy my-section">
                    <div>
                        <ul className="line-break"></ul>
                    </div>
                    <JoinClass />
                </div>

                <div id="my-className" className="section scrollspy my-section">
                    <StudentClass />
                </div>



                <div>
                    <ul className="line-break"></ul>
                </div>
            </div> */}
			</div>
		);
}

export default MyClass
