import { useAuth } from "../auth/AuthContext";
import { FirestoreCollection } from "@react-firebase/firestore";
import { fs } from "../auth/firebase";
import { useContext, useEffect, useRef, useState } from "react";
import { TestContext } from "../Context/TestContext";
import { Link } from "react-router-dom";
import handlePopState from "../helpers/popState";

const MyTest = () => {
	const { currentUser } = useAuth();
	const { setCurrentReview } = useContext(TestContext);
	const [page, setPage] = useState(0);
	const [result, setResult] = useState([]);

	const [currentCursor, setCurrentCursor] = useState();
	const [prevReadingCursor, setPrevReadingCursor] = useState();

	const handleGetReading = (e) => {
		e.target.classList.add("active");
		setPage(0);
		setCurrentCursor();
		setPrevReadingCursor();
		//setResult(reading);
	};
	const handleGetListening = (e) => {
		e.target.classList.add("active");
		setCurrentCursor([]);
		setPage(1);
		//setResult(listening);
	};

	useEffect(() => {
		window.removeEventListener("popstate", handlePopState);
		window.M.AutoInit();
	}, []);

	useEffect(() => {
		if (!currentUser) return;
		document.getElementById("current-page").innerText = "1";
		document.getElementById("prev-page").classList.remove("waves-effect");
		document.getElementById("prev-page").classList.add("disabled");
		document.getElementById("next-page").classList.add("waves-effect");
		document.getElementById("next-page").classList.remove("disabled");

		const getReadResult = async () => {
			const readResult = await fs
				.collection(
					`/submit/${currentUser.uid}/${page === 0 ? "reading" : "listening"}`
				)
				.orderBy("ClientTimestamp", "desc")
				.limit(5)
				.get();

			let ResultList = [];
			readResult.forEach((doc) => {
				if (!ResultList.length) setPrevReadingCursor(doc);
				setCurrentCursor(doc);
				ResultList.push({ id: doc.id, ...doc.data() });
			});
			if (readResult.size < 5) {
				document.getElementById("next-page").classList.remove("waves-effect");
				document.getElementById("next-page").classList.add("disabled");
				window.M.AutoInit();
			}
			setResult(ResultList);
		};

		getReadResult();
	}, [currentUser, page]);

	const nextPage = async (type) => {
		if (
			!currentUser ||
			document.getElementById("next-page").classList.contains("disabled")
		)
			return;
		// prevent flood
		document.getElementById("next-page").classList.add("disabled");
		document.getElementById("next-page").classList.remove("waves-effect");
		document.getElementById("prev-page").classList.add("disabled");
		document.getElementById("prev-page").classList.remove("waves-effect");

		const curPage = parseInt(document.getElementById("current-page").innerText);
		document.getElementById("current-page").innerText = curPage + 1;
		const readResult = await fs
			.collection(`/submit/${currentUser.uid}/${type}`)
			.limit(5)
			.orderBy("ClientTimestamp", "desc")
			.startAfter(currentCursor)
			.get();
		let ResultList = [];
		if (readResult.empty) {
			setPrevReadingCursor(currentCursor);
		} else {
			readResult.forEach((doc) => {
				if (!ResultList.length) setPrevReadingCursor(doc);
				setCurrentCursor(doc);
				ResultList.push({ id: doc.id, ...doc.data() });
			});
		}

		if (ResultList.length < 5) {
			document.getElementById("next-page").classList.remove("waves-effect");
			document.getElementById("next-page").classList.add("disabled");
			window.M.AutoInit();
		} else {
			// prevent flood
			document.getElementById("next-page").classList.remove("disabled");
			document.getElementById("next-page").classList.add("waves-effect");
		}
		setResult(ResultList);

		document.getElementById("prev-page").classList.remove("disabled");
		document.getElementById("prev-page").classList.add("waves-effect");
	};
	const prevPage = async (type) => {
		if (
			!currentUser ||
			document.getElementById("prev-page").classList.contains("disabled")
		)
			return;
		document.getElementById("next-page").classList.add("disabled");
		document.getElementById("next-page").classList.remove("waves-effect");
		document.getElementById("prev-page").classList.add("disabled");
		document.getElementById("prev-page").classList.remove("waves-effect");
		const curPage = parseInt(document.getElementById("current-page").innerText);
		document.getElementById("current-page").innerText = curPage - 1;
		const readResult = await fs
			.collection(`/submit/${currentUser.uid}/${type}`)
			.orderBy("ClientTimestamp", "desc")
			.endBefore(prevReadingCursor)
			.limitToLast(5)
			.get();
		let ResultList = [];
		readResult.forEach((doc) => {
			if (!ResultList.length) setPrevReadingCursor(doc);
			setCurrentCursor(doc);
			ResultList.push({ id: doc.id, ...doc.data() });
		});
		if (curPage === 2) {
			document.getElementById("prev-page").classList.remove("waves-effect");
			document.getElementById("prev-page").classList.add("disabled");
			window.M.AutoInit();
		} else {
			// prevent flood
			document.getElementById("prev-page").classList.remove("disabled");
			document.getElementById("prev-page").classList.add("waves-effect");
		}
		setResult(ResultList);
		document.getElementById("next-page").classList.remove("disabled");
		document.getElementById("next-page").classList.add("waves-effect");
	};

	return currentUser ? (
		<>
			{/* {page == 0 &&	
				reading.length ===
					0(
						<FirestoreCollection
							path={"/submit/" + currentUser.uid + "/reading"}
							limit={5}
							startAt={currentCursor}
						>
							{(d) => {
								if (d && d.isLoading === false) {
									console.log("get-ed-read-result");
									//getReadingRef.current && getReadingRef.current.click();
									if (d.value && d.value[0] === undefined) {
										return <> </>;
									}

									if (d.value === null) {
										return;
									}

									{
										d.value.map((result, idx) => {
											if (!result["index"]) result["index"] = d.ids[idx];
										});
									}

									setReading(d.value);
									setResult(d.value);
									setCheckResult(checkResult + 1);
									return <></>;
								}
							}}
						</FirestoreCollection>
					)}

			{page == 1 && listening.length === 0 && (
				<FirestoreCollection path={"/submit/" + currentUser.uid + "/listening"}>
					{(d) => {
						if (d && d.isLoading === false) {
							console.log("get-ed-lis-result");
							if (d.value && d.value[0] === undefined) {
								return <> </>;
							}

							if (d.value === null) {
								return;
							}
							{
								d.value.map((result, idx) => {
									if (!result["index"]) result["index"] = d.ids[idx];
								});
							}

							setListening(d.value);
							setResult(d.value);
							setCheckResult(checkResult + 1);
							return <></>;
						}
					}}
				</FirestoreCollection>
			)} */}

			<div className="container" style={{ width: "95%" }}>
				<div class="test-history" style={{ overflow: "auto", width: "100%" }}>
					{result.length === 0 && (
						<div className="row">
							{" "}
							<div
								className="col s8 offset-s2 alert ok"
								style={{ marginTop: "3em" }}
							>
								You have no results here.
							</div>{" "}
						</div>
					)}
					<h3 class="section-title">My Test History</h3>
					<div class="card-tabs">
						<ul class="tabs tabs-fixed-width">
							<li class="tab">
								<a
									className="active"
									href="#reading"
									onClick={handleGetReading}
								>
									Reading
								</a>
							</li>
							<li class="tab">
								<a className="" href="#listening" onClick={handleGetListening}>
									Listening
								</a>
							</li>
						</ul>
					</div>

					<table class="table table-result table-hover history-table iot-responsive-table">
						<thead>
							<tr>
								<th>Date</th>
								<th>Test name</th>
								<th>Test ID</th>
								<th>Type</th>
								<th>Score</th>
								<th></th>
							</tr>
						</thead>
						{/* <tbody>
							{result
								.sort((a, b) => -a.ClientTimestamp + b.ClientTimestamp)
								.map((result, idx) => {
									let result_meta = JSON.parse(result.Meta);
									let date = new Date(result.ClientTimestamp);
									return (
										<tr key={idx}>
											<td>{date.toLocaleString()}</td>
											<td>{result.Testname}</td>
											<td>
												{result_meta.once ? "not available" : result["index"]}
											</td>
											<td>{result_meta.type}</td>
											<td>{result_meta.numbercorrect}</td>
											<td
												style={{ display: "inline-table" }}
												onClick={() =>
													setCurrentReview([
														result.Key,
														result.Answer,
														result_meta,
													])
												}
											>
												{!result_meta.once && (
													<Link
														to={"/my-test/" + result["index"]}
														class="history-table__btn-view"
													>
														{" "}
														Review
														<i className="material-icons ">open_in_new</i>{" "}
													</Link>
												)}
											</td>
										</tr>
									);
								})}
						</tbody> */}

						<tbody>
							{result
								.sort((a, b) => -a.ClientTimestamp + b.ClientTimestamp)
								.map((result, idx) => {
									let result_meta = JSON.parse(result.Meta);
									let date = new Date(result.ClientTimestamp);
									return (
										<tr key={idx}>
											<td>{date.toLocaleString()}</td>
											<td>{result.Testname}</td>
											<td>{result_meta.once ? "not available" : result.id}</td>
											<td>{result_meta.type}</td>
											<td>{result_meta.numbercorrect}</td>
											<td
												style={{ display: "inline-table" }}
												onClick={() =>
													setCurrentReview([
														result.Key,
														result.Answer,
														result_meta,
													])
												}
											>
												{!result_meta.once && (
													<Link
														to={"/my-test/" + result.id}
														class="history-table__btn-view"
													>
														{" "}
														Review
														<i className="material-icons ">open_in_new</i>{" "}
													</Link>
												)}
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
					<ul class="pagination">
						<li class="disabled" id="prev-page">
							<a
								onClick={() => {
									prevPage(page === 0 ? "reading" : "listening");
								}}
							>
								<i class="material-icons">chevron_left</i>
							</a>
						</li>
						<li class="active">
							<a id="current-page">1</a>
						</li>

						<li class="waves-effect" id="next-page">
							<a
								onClick={() => {
									nextPage(page === 0 ? "reading" : "listening");
								}}
							>
								<i class="material-icons">chevron_right</i>
							</a>
						</li>
					</ul>
					{/* 
					<p class="italic">Notes: The review shows the 5 latest tests.</p> */}
					<p class="italic">
						Tips: You can improve the score by taking test again.
					</p>
				</div>
			</div>
		</>
	) : (
		<div className="row">
			<div className="col s8 offset-s2 alert" style={{ marginTop: "3em" }}>
				{" "}
				Please log in to see your test
			</div>
		</div>
	);
};

export default MyTest;
