import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from './AuthContext';
import { useHistory, Link } from 'react-router-dom';
import fb, { uiConfig } from './firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { motion } from 'framer-motion';
import { pageTransition, pageVariant } from '../styles/pageTransition';

export default function Login() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const history = useHistory();
    const { login, currentUser } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
        } catch {
            setLoading(false)
            setError("Username / password not matched. Please try again")
        }

    }

    useEffect(() => {
        if (currentUser && currentUser.emailVerified === true) {
            history.push('/')
        } else if (currentUser) {
            history.push('/email-confirm')
        }
    }, [currentUser])

    return (
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={pageVariant}
            transition={pageTransition}
        >
            <div className="container signup-container">
                <div className="row signup signup-wrapper">
                    <form className="col l6 s12" onSubmit={handleSubmit}>
                        <div className="row center">
                            <h4>Login</h4>
                        </div>
                        {error && <div className="alert">
                            {error}
                        </div>}
                        <div className="row signup">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">email</i>
                                <input id="email" type="email" ref={emailRef} required className="validate" />
                                <label htmlFor="email">Email</label>
                            </div>
                            <div className="input-field col s12">
                                <i className="material-icons prefix">vpn_key</i>
                                <input id="password" type="password" ref={passwordRef} required className="validate" />
                                <label htmlFor="password">Password</label>
                            </div>
                        </div>

                        <div>
                            <ul>
                                <li>
                                    <button className="btn signup" disabled={loading}>Log in</button>
                                    {loading &&
                                        <div style={{ marginTop: '1em', textAlignLast: 'center' }}>
                                            <div className="preloader-wrapper active">
                                                <div className="spinner-layer spinner-red-only">
                                                    <div className="circle-clipper left">
                                                        <div className="circle"></div>
                                                    </div><div className="gap-patch">
                                                        <div className="circle"></div>
                                                    </div><div className="circle-clipper right">
                                                        <div className="circle"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </li>
                            </ul>
                        </div>

                        <div className="signup prompt center">
                            Trouble with your account? <br /> <Link style={{ fontStyle: 'italic' }} to="/sign-up">Sign up </Link>
                            or <Link style={{ fontStyle: 'italic' }} to="/forgot-password">Forgot Password.</Link>
                        </div>

                    </form>
                    <div className="form-border-right col l1 hide-on-med-and-down"></div>
                    <div className="col l5 s12">
                        <StyledFirebaseAuth className="mt-5" uiConfig={uiConfig} firebaseAuth={fb.auth()} />
                    </div>
                </div>



            </div>
            <ul className="line-break"></ul>
        </motion.div>

    )
}
