import { useAuth } from "../auth/AuthContext";
import SplitPaneReading from "./SplitPane";
import { Redirect } from 'react-router-dom';
import { FirestoreMutation } from "@react-firebase/firestore";
import { useContext } from "react";
import { TestContext } from "../Context/TestContext";

const Reading = (props) => {
    const { currentUser } = useAuth();
    const { testInfo } = useContext(TestContext)
    return testInfo ? (
            <FirestoreMutation type="set" path={"/submit/" + currentUser.uid + '/reading/' + testInfo.id}>
                {({ runMutation }) => {
                    return currentUser ? (
                        <SplitPaneReading runMutation={runMutation} id={testInfo.id} />
                    ) : (<Redirect to="/" />);
                }}
            </FirestoreMutation>
    ) : (<Redirect to="/" />);
}

export default Reading;


