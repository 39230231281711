import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navbar from './components/layout/Navbar'
import Logo from './components/layout/Logo';
import Home from './components/layout/Home';
import './App.css'
import Login from './components/auth/Login';
import Signup, { SignUpThirdParty } from './components/auth/Signup';
import Logout from './components/auth/Logout';
import Profile from './components/auth/Profile';
import ForgotPassword from './components/auth/ForgotPassword';
import PrivateRoute from './components/auth/PrivateRoute';
import Reading from './components/reading/Reading';
import MyTest from './components/mytest/MyTest';
import Done from './components/mytest/Done';
import EmailConfirm from './components/auth/EmailConfirm';
import FSProvider from './components/FS/FireStoreContext'
import JoinTest from './components/mytest/JoinTest'
import { TestContext } from './components/Context/TestContext'
import { useState } from 'react';
import ReviewTest from './components/mytest/Review';
import Footer from './components/layout/Footer';
import Listening from './components/listening/Listening';
import Writing from './components/writing/Writing';
import AdminHome from './components/admin/home/AdminHome';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import TeacherPage from './components/admin/classes/Teacher';
import MyClass from './components/JoinClass/MyClass';
import ScrollToTop from './components/helpers/ScrollToTop';
import { AnimatePresence } from "framer-motion"

function App() {
  const [isTest, setIsTest] = useState(false);
  const [currentReview, setCurrentReview] = useState();
  const [testInfo, setTestInfo] = useState();
  return (
		<FSProvider>
			<BrowserRouter>
				<TestContext.Provider
					value={{
						isTest,
						setIsTest,
						currentReview,
						setCurrentReview,
						testInfo,
						setTestInfo,
					}}
				>
					<div className="App" style={{ minHeight: "100vh" }}>
						<Logo />
						<Navbar />
						<ScrollToTop />
						<Route
							render={({ location }) => (
								<AnimatePresence exitBeforeEnter>
									<Switch location={location} key={location.key}>
										<Route exact path="/" component={Home} />
										<Route path="/log-in" component={Login} />
										<Route path="/sign-up" component={Signup} />
										<Route path="/log-out" component={Logout} />
										<Route path="/forgot-password" component={ForgotPassword} />
										<PrivateRoute path="/join" component={MyClass} />
										<PrivateRoute path="/teacher" component={TeacherPage} />
										<Route path="/email-confirm" component={EmailConfirm} />
										<Route
											path="/signupthirdparty"
											component={SignUpThirdParty}
										/>
										<PrivateRoute path="/admin" component={AdminHome} />
										<PrivateRoute path="/admin-class" component={TeacherPage} />
										<PrivateRoute path="/done" component={Done} />
										<PrivateRoute path="/take-test/:id" component={JoinTest} />
										<PrivateRoute path="/take-test" component={JoinTest} />
										<PrivateRoute path="/profile" component={Profile} />
										<PrivateRoute path="/reading/:id" component={Reading} />
										<PrivateRoute path="/listening/:id" component={Listening} />
										<PrivateRoute path="/writing/:id" component={Writing} />
										<PrivateRoute exact path="/my-test" component={MyTest} />
										<PrivateRoute path="/my-test/:id" component={ReviewTest} />
									</Switch>
								</AnimatePresence>
							)}
						></Route>
					</div>

					{isTest === false && (
						<MessengerCustomerChat
							pageId="1672728689605349"
							appId="366167108187473"
						/>
					)}
					<Footer />
				</TestContext.Provider>
			</BrowserRouter>
		</FSProvider>
	);
}

export default App;
