import { useContext, useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/AuthContext";
import { TestContext } from "../Context/TestContext";
import Countdown from "react-countdown";
import ButtonSubmit from "../helpers/btnSubmit";
import Timed from "../helpers/timed";
import { FirestoreDocument } from "@react-firebase/firestore";
import "./Write.css";
import ReactHtmlParser from "react-html-parser";
import firebase from "firebase/app";
import JoditEditor from "jodit-react";
import { useHistory } from "react-router-dom";

const MAXTIME = 60 * 60 * 1000;

const WritingPane = ({ runMutation, id }) => {
	const history = useHistory();
	const time = useRef();
	const editor = useRef(null);
	const editor2 = useRef(null);
	const { currentUser } = useAuth();
	const [studentContent, setStudentContent] = useState("");
	const [studentContent2, setStudentContent2] = useState("");
	const [loading, setLoading] = useState(false);

	const [content, setContent] = useState();
	const { setIsTest } = useContext(TestContext);
	const [isopen, setIsOpen] = useState(false);

	const config = {
		readonly: false,
		buttons: "",
		autofocus: false,
		showCharsCounter: false,
		showXPathInStatusbar: false,
		minHeight: 500,
	};

	const onStartClock = () => {
		let date = new Date();
		localStorage.setItem("write-date" + id, time.current.props.date);
		if (localStorage.getItem("write-start" + id)) {
			localStorage.setItem(
				"write-start" + id,
				localStorage.getItem("write-start" + id)
			);
		} else localStorage.setItem("write-start" + id, date.getTime());
	};

	const renderer = ({ minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			return <span>Timed!</span>;
		} else {
			// Render a countdown
			return (
				<span>
					{minutes.toLocaleString("en-US", {
						minimumIntegerDigits: 2,
						useGrouping: false,
					})}
					:
					{seconds.toLocaleString("en-US", {
						minimumIntegerDigits: 2,
						useGrouping: false,
					})}
				</span>
			);
		}
	};

	const finish = () => {
		setIsOpen(true);
	};

	async function handleSubmit() {
		setLoading(true);
		let date = new Date();
		let elapsed = localStorage.getItem("write-start" + id)
			? Math.round(
					(date.getTime() -
						parseInt(localStorage.getItem("write-start" + id))) /
						1000
			  )
			: 0;
		if (elapsed > 60 * 60) elapsed = 60 * 60;

		var meta = {
			type: "Writing",
			elapsed: elapsed,
			task1: localStorage.getItem("task1"),
			task2: localStorage.getItem("task2"),
		};

		localStorage.removeItem("write-start" + id);
		localStorage.removeItem("write-date" + id);
		localStorage.removeItem("task1");
		localStorage.removeItem("task2");

		//DEV

		// console.log("Meta", meta);

		//END DEV

		try {
			await runMutation({
				ClientTimestamp: Date.now(),
				ServerTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
				Name: currentUser.displayName,
				Email: currentUser.email,
				Meta: JSON.stringify(meta),
				Testname: content.testname,
			}).then((res) => {
				//console.log(res)
				window.alert("Writing result will be returned to your email later.");
				history.replace("/");
			});
		} catch (err) {
			window.alert(err);
			console.log(err);
		}
	}

	const btnSubmit = () => {
		return <ButtonSubmit handleSubmit={handleSubmit} />;
	};

	useEffect(() => {
		setIsTest(true);
		return () => {
			setIsTest(false);
		};
	}, []);

	return loading ? (
		<div className="row">
			<div className="col s8 offset-s2 alert ok">Loading...</div>
		</div>
	) : (
		<>
			{!content && (
				<FirestoreDocument path={"/writing/" + id}>
					{(d) => {
						if (d.value && d.isLoading === false) {
							{
								/* console.log(d) */
							}
							setContent(d.value);
							console.log("write-get-ed");
						}
					}}
				</FirestoreDocument>
			)}

			<Timed btn={btnSubmit} isopen={isopen} />
			<div className="container-listening">
				<div className="testing listening-page">
					<div className="lp-left">
						<div className="split-left">
							{content && ReactHtmlParser(content.task1)}
							{/* <div>
                                <h4 class='bold red-text'>WRITING TASK 1
                                </h4>
                                <p class="italic"> You should spend about 20 minutes on this task.</p>


                                <p class="italic bold">
                                    The pie and bar charts below show the percentage of water consumption and use in Australia
                                    in 2004. Summarize the information by selecting and reporting the main features and make
                                    comparisons where relevant.
                                </p>
                                <p class='center'><img alt=""
                                    src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/test/130721.PNG"
                                /></p>


                            </div> */}
							<JoditEditor
								ref={editor}
								value={studentContent}
								config={config}
								tabIndex={1} // tabIndex of textarea
								onBlur={(newContent) => setStudentContent(newContent)} // preferred to use only this option to update the content for performance reasons
								onChange={(newContent) =>
									localStorage.setItem("task1", newContent)
								} // preferred to use only this option to update the content for performance reasons
							/>
							<ul className="line-break"></ul>

							{content && ReactHtmlParser(content.task2)}
							{/* <div>
                                <h4 class='bold red-text'> WRITING TASK 2
                                </h4>
                                <p class="italic"> You should spend about 40 minutes on this task.</p>
                                <p class="italic">Write about the following topic:</p>


                                <p class="italic bold">
                                    Some people believe that allowing children to make their own choices on everyday matters
                                    (such as food, clothes and entertainment) is likely to result in a society of individuals who only
                                    think about their own wishes. Other people believe that it is important for children to make
                                    decisions about matters that affect them.
                                </p>
                                <p class="italic"> Discuss both these views and give your own opinion.</p>
                                <p class="italic"> Give reasons for your answer and include any relevant examples from your own
                                    knowledge or experience.</p>
                                <p class="italic">
                                    Write at least <span class="bold red-text">250 words.</span></p>

                            </div> */}

							<JoditEditor
								ref={editor2}
								value={studentContent2}
								config={config}
								tabIndex={1} // tabIndex of textarea
								onBlur={(newContent) => setStudentContent2(newContent)} // preferred to use only this option to update the content for performance reasons
								onChange={(newContent) =>
									localStorage.setItem("task2", newContent)
								} // preferred to use only this option to update the content for performance reasons
							/>
							<ul className="line-break"></ul>
						</div>
					</div>
					<div className="lp-right lis-clock write-clock">
						<div className="container"></div>
						<div className="affix write">
							<div className="listening-footer write">
								<div className="self-center mgl-5p write lis-clock">
									<Countdown
										date={
											localStorage.getItem("write-date" + id) &&
											parseInt(localStorage.getItem("write-date" + id)) >
												Date.now()
												? parseInt(localStorage.getItem("write-date" + id))
												: Date.now() + MAXTIME
										}
										daysInHours={true}
										ref={time}
										onComplete={finish}
										onStart={onStartClock}
										renderer={renderer}
									/>
								</div>
								<div className="self-center mgl-5p">{btnSubmit()}</div>
							</div>
						</div>
					</div>
					<div className="clearfix hide-on-small-only"></div>
				</div>
			</div>
		</>
	);
};

export default WritingPane;
