const raw = correct => {
    if (correct <= 2) return correct + 0.0;
    if (correct == 3) return 2.5;
    if (4 <=correct && correct <= 5) return 3.0;
    if (6 <=correct && correct <= 9) return 3.5;
    if (10 <=correct && correct <= 12) return 4.0;
    if (13 <=correct && correct <= 14) return 4.5;
    if (15 <=correct && correct <= 18) return 5.0;
    if (19 <=correct && correct <= 22) return 5.5;
    if (23 <=correct && correct <= 26) return 6.0;
    if (27 <=correct && correct <= 29) return 6.5;
    if (30 <=correct && correct <= 32) return 7.0;
    if (33 <=correct && correct <= 34) return 7.5;
    if (35 <=correct && correct <= 36) return 8.0;
    if (37 <=correct && correct <= 38) return 8.5;
    if (39 <= correct && correct <= 40) return 9.0;
    return NaN;
}


const bandCal = ({ correct, total }) => {
    return raw(Math.ceil(correct * 40 / total)).toFixed(1);
}

export default bandCal;