import { useContext, useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/AuthContext";
import { FirestoreDocument } from "@react-firebase/firestore";
import TestFound from "./TestFound";
import { TestContext } from "../Context/TestContext";
import handlePopState from "../helpers/popState";
var CryptoJS = require("crypto-js");

const JoinTest = (props) => {
	const { currentUser } = useAuth();
	const testID = useRef(null);
	const [Meta, setMeta] = useState();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [decrypted, setDecypted] = useState();
	const [isopen, setIsOpen] = useState(false);
	const [submit, setSubmit] = useState(false);
	const { setTestInfo } = useContext(TestContext);

	const handleSubmit = (e) => {
		if (e) e.preventDefault();
		if (!testID.current.value) {
			testID.current.focus();
			return;
		}

		// 3 bai nay bi lo ID roi!
		// switch (testID.current.value) {
		//     case "placement-lis":
		//         setMessage("Test ID not found")
		//         return;
		//     case "placement-read":
		//         setMessage("Test ID not found")
		//         return;
		//     case "placement-write":
		//         setMessage("Test ID not found")
		//         return;
		// }

		var decryptedTemp = "";
		try {
			decryptedTemp = CryptoJS.AES.decrypt(
				testID.current.value,
				currentUser.email
			).toString(CryptoJS.enc.Utf8);
			console.log("decryptedTemp", decryptedTemp);
		} catch (error) {
			console.log("ERR", error);
		}
		if (decryptedTemp) {
			setDecypted(decryptedTemp);
		} else {
			setDecypted(testID.current.value);
		}
		window.removeEventListener("popstate", handlePopState);
		setMessage("");
		setLoading(true);
		setSubmit(true);
	};

	useEffect(() => {
		if (props.match.params.id) {
			testID.current.value = props.match.params.id;
			handleSubmit();
		}
	}, []);

	const acceptID = () => {
		setMessage(null);
		setIsOpen(true);
		setLoading(false);
		setSubmit(false);
	};

	return currentUser ? (
		<>
			<div className="row">
				<form
					className="col s4 offset-s4"
					style={{ marginTop: "3em" }}
					onSubmit={handleSubmit}
				>
					{message && <div className="alert">{message} </div>}
					{loading && (
						<div style={{ marginTop: "1em", textAlignLast: "center" }}>
							<div className="preloader-wrapper active">
								<div className="spinner-layer spinner-red-only">
									<div className="circle-clipper left">
										<div className="circle"></div>
									</div>
									<div className="gap-patch">
										<div className="circle"></div>
									</div>
									<div className="circle-clipper right">
										<div className="circle"></div>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className="input-field row s1">
						<input
							placeholder="ID"
							id="test_id"
							type="text"
							className="validate"
							ref={testID}
						/>
						<label htmlFor="test_id" className="active">
							Test ID
						</label>
					</div>
					<button
						style={{ fontWeight: "bold", zIndex: "0" }}
						className="row s2 btn waves-effect waves-light main-theme-back"
					>
						{" "}
						Take test
						<i className="material-icons right">send</i>
					</button>
				</form>
			</div>

			{submit && (
				<FirestoreDocument path={"/ids/" + decrypted}>
					{(d) => {
						if (d && d.isLoading === false) {
							if (
								d.value &&
								["reading", "listening", "writing"].includes(
									d.value.type.toLowerCase()
								)
							) {
								setMeta(d.value);
								if (d.value.once) {
									return (
										<>
											<FirestoreDocument
												path={
													"/submit/" +
													currentUser.uid +
													"/" +
													d.value.type.toLowerCase() +
													"/" +
													decrypted
												}
											>
												{(d) => {
													if (d && d.isLoading === false) {
														if (d.value) {
															setMessage("You have done this test");
															setLoading(false);
															setSubmit(false);
														} else {
															//setIsTest({ once: true })
															setTestInfo({ once: true });
															acceptID();
														}
														return <></>;
													}
												}}
											</FirestoreDocument>
										</>
									);
								} else {
									setTestInfo({ once: false });
									acceptID();
								}
								return <></>;
							} else {
								setMessage("Test ID not found");
								setLoading(false);
								setSubmit(false);
							}
						}
					}}
				</FirestoreDocument>
			)}

			{isopen && (
				<TestFound
					isopen={setIsOpen}
					id={testID.current.value}
					idDecrypt={decrypted}
					meta={Meta}
					history={props.history}
				/>
			)}
		</>
	) : (
		<div className="row">
			<div className="col s8 offset-s2 alert" style={{ marginTop: "3em" }}>
				{" "}
				Please log in to take the test
			</div>
		</div>
	);
};

export default JoinTest;
