import { useCallback, useEffect } from "react"
import axios from "axios"
import "../home/AdminHome.css"
import JoinRequest from "./JoinRequest"
const TeacherPage = () => {

    useEffect(() => {
        var elems = document.querySelectorAll('.scrollspy');
        var instances = window.M.ScrollSpy.init(elems);
    }, [])
    return (
        <div className="row">
            <div className="col hide-on-small-only m3 l2">
                <ul className="section table-of-contents pinned">
                    <li><a href="#join-request">Join Request</a></li>
                    <li><a href="#all-classes">All Classes</a></li>
                </ul>
            </div>

            <div className="col s12 m9 l10 h-100">
                <div style={{ height: '100vh' }} id="join-request" className="section scrollspy my-section">
                    <JoinRequest />
                </div>


                <div>
                    <ul className="line-break"></ul>
                </div>
                <div style={{ height: '100vh' }} id="all-classes" className="section scrollspy my-section">
                    All Classes
                </div>
                <div>
                    <ul className="line-break"></ul>
                </div>
                {/* <div id="check-student-result" className="section scrollspy my-section">
                    <TeacherClass />
                </div>
                <div id="check-student-result" className="section scrollspy my-section">
                    <TeacherClass />
                </div> */}
            </div>

        </div>

    );
}

export default TeacherPage
