import "./css/Home.scss";
import SwiperCore, { Navigation } from "swiper/core";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import { useContext, useEffect } from "react";
import { TestContext } from "../Context/TestContext";
import Section1 from "./Section1";
import Section2 from "./Section2";
import SectionBottom from "./SectionBottom";
import "swiper/components/effect-fade/package.json";
import { motion } from "framer-motion";
import { pageTransition, pageVariant } from "../styles/pageTransition";

SwiperCore.use([Autoplay, Navigation]);
const Home = () => {
	const { isTest, setIsTest } = useContext(TestContext);
	useEffect(() => {
		if (isTest) setIsTest(false);
	}, []);
	return (
		<motion.div
			initial="out"
			animate="in"
			exit="out"
			variants={pageVariant}
			transition={pageTransition}
		>
			<main>
				<Swiper className="mySwiper" autoplay={{ delay: 1500 }} navigation>
					<SwiperSlide>
						<img
							src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/slide-1407-1.jpg"
							alt=""
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/slide-1407-2.jpg"
							alt=""
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/s1.jpg"
							alt=""
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/s2.jpg"
							alt=""
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/s8.jpg"
							alt=""
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/s3.jpg"
							alt=""
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/s5.jpg"
							alt=""
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/s6.jpg"
							alt=""
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/s7.jpg"
							alt=""
						/>
					</SwiperSlide>
					<SwiperSlide>
						<img
							src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/s4.jpg"
							alt=""
						/>
					</SwiperSlide>
				</Swiper>
			</main>

			<Section1 />
			<Section2 />
			<SectionBottom />
		</motion.div>
	);
};

export default Home;
