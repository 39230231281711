import { useRef, memo, useEffect, useContext, useState } from "react";
import Countdown from "react-countdown";
import { TestContext } from "../Context/TestContext";
import Highlighter from "web-highlighter";

const ReadingTestFooter = ({
	btnSubmit,
	spLeftRef,
	spRightRef,
	setIsOpen,
	id,
	width,
}) => {
	const time = useRef("");
	const navExtendRef = useRef("");
	const Pass1Ref = useRef("");
	const Pass2Ref = useRef("");
	const Pass3Ref = useRef("");
	const { testInfo } = useContext(TestContext);
	var highlighter;

	const finish = () => {
		setIsOpen(true);
	};
	const remaining = () => {
		let date = new Date();
		localStorage.setItem("date" + id, time.current.props.date);
		if (localStorage.getItem("start" + id)) {
			localStorage.setItem("start" + id, localStorage.getItem("start" + id));
		} else localStorage.setItem("start" + id, date.getTime());
	};
	const renderer = ({ minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			return <span>Timed!</span>;
		} else {
			// Render a countdown
			return (
				<span>
					{minutes.toLocaleString("en-US", {
						minimumIntegerDigits: 2,
						useGrouping: false,
					})}
					:
					{seconds.toLocaleString("en-US", {
						minimumIntegerDigits: 2,
						useGrouping: false,
					})}
				</span>
			);
		}
	};

	const expandHandle = () => {
		if (width < 768) return;
		spLeftRef.current.style.maxHeight =
			spLeftRef.current.style.maxHeight === "95vh" ? "91vh" : "95vh";
		spRightRef.current.style.maxHeight =
			spRightRef.current.style.maxHeight === "95vh" ? "91vh" : "95vh";
		navExtendRef.current.style.height =
			navExtendRef.current.style.height === "auto" ? "70px" : "auto";
	};

	useEffect(() => {
		// $(document).ready(function () {
		//     console.log($('.tabs').init(($('.tabs')[0]),[]))
		// });
		Pass1Ref.current && Pass1Ref.current.click();
		Pass1();
		initHighLightBtn();
		return () => {
			if (highlighter) {
				highlighter.stop();
				highlighter.dispose();
            }
            window.document.body.classList.remove("highlighting");
		};
	}, []);

	const initHighLightBtn = () => {
		var elems = document.querySelectorAll(".fixed-action-btn");
		var instances = window.M.FloatingActionButton.init(elems, {
			hoverEnabled: false,
			direction: width < 768 ? "left" : "right",
		});

		//highlighter
		elems[0].addEventListener("mouseenter", () => {
			elems[0].style.opacity = 1;
		});
		elems[0].addEventListener("mouseleave", () => {
			elems[0].style.opacity = 0.5;
		});
	};

	const turnOnHighlighter = (color) => {
		if (highlighter) {
			highlighter.stop();
		}
		localStorage.setItem("hl", "1");
		window.document.body.classList.add("highlighting");
		highlighter = new Highlighter({
			$root: window.document.getElementById("highlight-wrapper-reading"),
			exceptSelectors: [
				"img",
				"input",
				"select",
				".section-image",
			],
			wrapTag: "span",
			wrapTag: "span",
			style: {
				className: "highlight-theforum-" + color,
			},
		});

		highlighter
			.on("selection:hover", ({ id }) => {
				// display different bg color when hover
				if (localStorage.getItem("hl") === "1")
					highlighter.addClass("highlight-cursor", id);
				window.document.body.classList.remove("highlighting");
			})
			.on("selection:click", ({ id }) => {
				// display different bg color when hover

				if (localStorage.getItem("hl") === "1") highlighter.remove(id);
			})

			.on("selection:hover-out", ({ id }) => {
				highlighter.removeClass("highlight-cursor", id);
				if (
					!window.document.body.classList.contains("highlighting") &&
					localStorage.getItem("hl") === "1"
				)
					window.document.body.classList.add("highlighting");
			})

			.on("selection:remove", ({ id }) => {
				highlighter.removeClass("highlight-cursor", id);
			});

		highlighter.run();
	};

	const turnOffHighlighter = () => {
		if (highlighter) {
			highlighter.stop();
		}
		window.document.body.classList.remove("highlighting");
		localStorage.setItem("hl", "0");
	};

	const Pass1 = () => {
		Pass1Ref.current.classList = "active";
		Pass2Ref.current.classList = "";
		Pass3Ref.current.classList = "";
		if (document.getElementById("passage-1-wrapper"))
			document.getElementById("passage-1-wrapper").style.display = "block";

		if (document.getElementById("passage-2-wrapper"))
			document.getElementById("passage-2-wrapper").style.display = "none";

		if (document.getElementById("passage-3-wrapper"))
			document.getElementById("passage-3-wrapper").style.display = "none";

		if (document.getElementById("set-question-1"))
			document.getElementById("set-question-1").style.display = "block";

		if (document.getElementById("set-question-2"))
			document.getElementById("set-question-2").style.display = "none";

		if (document.getElementById("set-question-3"))
			document.getElementById("set-question-3").style.display = "none";
	};
	const Pass2 = () => {
		Pass1Ref.current.classList = "";
		Pass2Ref.current.classList = "active";
		Pass3Ref.current.classList = "";
		if (document.getElementById("passage-1-wrapper"))
			document.getElementById("passage-1-wrapper").style.display = "none";

		if (document.getElementById("passage-2-wrapper"))
			document.getElementById("passage-2-wrapper").style.display = "block";

		if (document.getElementById("passage-3-wrapper"))
			document.getElementById("passage-3-wrapper").style.display = "none";

		if (document.getElementById("set-question-1"))
			document.getElementById("set-question-1").style.display = "none";

		if (document.getElementById("set-question-2"))
			document.getElementById("set-question-2").style.display = "block";

		if (document.getElementById("set-question-3"))
			document.getElementById("set-question-3").style.display = "none";
	};
	const Pass3 = () => {
		Pass1Ref.current.classList = "";
		Pass2Ref.current.classList = "";
		Pass3Ref.current.classList = "active";
		if (document.getElementById("passage-1-wrapper"))
			document.getElementById("passage-1-wrapper").style.display = "none";

		if (document.getElementById("passage-2-wrapper"))
			document.getElementById("passage-2-wrapper").style.display = "none";

		if (document.getElementById("passage-3-wrapper"))
			document.getElementById("passage-3-wrapper").style.display = "block";

		if (document.getElementById("set-question-1"))
			document.getElementById("set-question-1").style.display = "none";

		if (document.getElementById("set-question-2"))
			document.getElementById("set-question-2").style.display = "none";

		if (document.getElementById("set-question-3"))
			document.getElementById("set-question-3").style.display = "block";
	};

	return (
		<>
			<div class="highlight-btn fixed-action-btn">
				<a class="btn-floating btn-large red">
					<i class="large material-icons">auto_fix_normal</i>
				</a>
				<ul>
					<li>
						<a
							class="btn-floating orange lighten-1"
							onClick={() => turnOnHighlighter("orange")}
						/>
					</li>
					<li>
						<a
							class="btn-floating yellow lighten-3"
							onClick={() => turnOnHighlighter("yellow")}
						/>
					</li>
					<li>
						<a
							class="btn-floating green lighten-2"
							onClick={() => turnOnHighlighter("green")}
						/>
					</li>
					<li>
						<a
							class="btn-floating purple lighten-1"
							onClick={() => turnOnHighlighter("purple")}
						/>
					</li>
					<li>
						<a
							class="btn-floating cyan lighten-2"
							onClick={() => turnOnHighlighter("cyan")}
						/>
					</li>
					<li>
						<a
							class="btn-floating red lighten-1"
							onClick={() => turnOnHighlighter("red")}
						/>
					</li>
					<li>
						<a class="btn-floating grey" onClick={turnOffHighlighter}>
							<i class="large material-icons">auto_fix_off</i>
						</a>{" "}
					</li>
				</ul>
			</div>

			<nav
				className="nav-extended footer-reading"
				ref={navExtendRef}
				style={{
					position: "fixed",
					marginBottom: "-20px",
					bottom: "0",
					backgroundColor: "rgb(0,0,0)",
					height: "auto",
				}}
			>
				<div className="nav-wrapper">
					<ul className="left">
						<div>
							<span
								className="btn-flat expand"
								style={{ height: "unset" }}
								onClick={expandHandle}
							>
								<i
									className="material-icons"
									style={{
										fontSize: "2em",
										color: "red",
										backgroundColor: "transparent",
									}}
								>
									reorder
								</i>
							</span>
						</div>
					</ul>
					<ul
						className="brand-logo center main-theme-text"
						style={{ position: "fixed" }}
					>
						<Countdown
							date={
								localStorage.getItem("date" + id) &&
								parseInt(localStorage.getItem("date" + id)) > Date.now()
									? parseInt(localStorage.getItem("date" + id))
									: Date.now() + testInfo.time * 60 * 1000
							}
							daysInHours={true}
							ref={time}
							onComplete={finish}
							onStart={remaining}
							renderer={renderer}
						/>
					</ul>

					{/* <a href="#trigger" className="sidenav-trigger"><i className="material-icons">menu</i></a> */}
				</div>
				<div className="container">
					<div className="nav-content">
						<ul className="tabs tabs-transparent">
							<li className="tab">
								<a ref={Pass1Ref} href="#passage-1" onClick={Pass1}>
									Passage 1
								</a>
							</li>
							<li className="tab">
								<a ref={Pass2Ref} href="#passage-2" onClick={Pass2}>
									Passage 2
								</a>
							</li>
							<li className="tab">
								<a ref={Pass3Ref} href="#passage-3" onClick={Pass3}>
									Passage 3
								</a>
							</li>
							<li className="tab right">{btnSubmit()}</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
};

export default memo(ReadingTestFooter);
