import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { TestContext } from '../Context/TestContext';
import { pageTransition, pageVariant } from '../styles/pageTransition';
import { useAuth } from './AuthContext';


const PrivateRoute = ({ component: Component, ...rest }) => {
    const { currentUser } = useAuth();
    const { isTest } = useContext(TestContext)
    return (
        <Route
            {...rest}
            render={props => {
                return currentUser ?
                    (
                        (currentUser.emailVerified) ? ((currentUser.displayName.split(':')[1] ?
                            !isTest ?
                                (<motion.div
                                    initial="out"
                                    animate="in"
                                    exit="out"
                                    variants={pageVariant}
                                    transition={pageTransition}
                                >

                                    <Component {...props} />
                                </motion.div>) :
                                <Component {...props} />
                            : <Redirect to="/signupthirdparty" />)) :
                            (<Redirect to="/email-confirm" />)
                    ) : (<Redirect to="/" />)
            }}
        ></Route>
    );
}

export default PrivateRoute;