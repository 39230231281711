import React from 'react'
import { NavLink } from 'react-router-dom'
import { useAuth } from '../auth/AuthContext';
import { Link } from 'react-router-dom';


const SignedInLinks = () => {
    const { currentUser, nav } = useAuth();
    var displayname = "";
    if (currentUser && currentUser.displayName) {
        displayname = currentUser.displayName.split(':')[0].split(' ');
        displayname = displayname[displayname.length - 1]
    }
    return (
        <ul className="right">
            <li><NavLink to='/log-out'>Log Out</NavLink></li>
            <li>
                <NavLink to='/profile' className='btn-large btn-floating profile'> {currentUser.photoURL ? <img style={{ width: '100%' }} src={currentUser.photoURL} alt=""></img> : displayname[0]} </NavLink>
            </li>
        </ul>
    );
}

export default SignedInLinks;