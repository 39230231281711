import axios from "axios";
import JoditEditor from "jodit-react";
import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import ReactToPrint from "react-to-print";
import { config } from "./joditConfig";
import bandCal from "./bandCalculator";
import GenId from "./genID";
import GenResult from "./genResult";
import ResultTable from "./resultTable";
import "./AdminHome.css";
import SuperAdmin from "../superAdmin/superAdmin";
import { useAuth } from "../../auth/AuthContext";

const client = axios.create({
	baseURL: "https://admin-theforum-api.herokuapp.com",
	// baseURL: "http://localhost:5000",
});

var total_temp;

const AdminHome = () => {
	const editor = useRef(null);
	const [content, setContent] = useState("");
	const [superAdmin, setSuperAdmin] = useState(false);
	const [loading, setLoading] = useState(false);
	const [wakeup, setWakeup] = useState(false);
	const [message, setMessage] = useState("");
	const [name, setName] = useState();
	const [testName, setTestName] = useState();
	const [readingResult, setReadingResult] = useState();
	const readingRef = useRef();
	const [listeningResult, setListeningResult] = useState();
	const listeningRef = useRef();
	const [writingResult, setWritingResult] = useState();
	const writingWrapperRef = useRef();
	const { currentUser } = useAuth();

	const ReadingResult = useMemo(() => [], []);
	const ListeningResult = useMemo(() => [], []);
	var metaResult = useMemo(() => [{}], []);

	const fetchAPI = useCallback((type) => {
		return client.get(type);
	}, []);

	useEffect(() => {
		window.M.AutoInit(); //init lan dau
	}, [wakeup]);

	const wakeupServer = useCallback(async () => {
		console.log(currentUser);
		const res = await fetchAPI("/staff/is-super-admin?uid=" + currentUser.uid);
		if (res.data.admin) setSuperAdmin(true);
		setWakeup(true);
	}, []);

	const hideEvidence = () => {
		document.querySelectorAll(".evidence").forEach((item) => {
			if (item.style.display === "none") item.style.display = "block";
			else {
				item.style.display = "none";
			}
		});
	};

	useEffect(() => {
		if (writingResult)
			document
				.getElementsByClassName("writing-result-wrapper")[0]
				.querySelectorAll("img")
				.forEach((item) => {
					item.style.maxWidth = "400px";
				});
	}, [writingResult]);

	useEffect(() => {
		if (currentUser) wakeupServer();
	}, [currentUser]);

	async function mapResult(response, testname, testID) {
		setName(response["NAME"].split(":")[0]);
		if (response["READING-KEY"]) {
			ReadingResult.push({ key: response["READING-KEY"] });
			ReadingResult.push({
				answer: response["READING-ANSWER"],
				evidence: response["READING-EVIDENCE"],
			});
			total_temp = 0;
			response["READING-ANSWER"].map((ans) => {
				total_temp += ans[0];
			});
			metaResult["reading-meta"] = {
				correct: response["READING-META"].numbercorrect,
				total: total_temp,
			};
			setReadingResult(true);
		}

		if (response["LISTENING-KEY"]) {
			ListeningResult.push({ key: response["LISTENING-KEY"] });
			ListeningResult.push({ answer: response["LISTENING-ANSWER"] });

			total_temp = 0;
			response["LISTENING-ANSWER"].map((ans) => {
				total_temp += ans[0];
			});
			metaResult["listening-meta"] = {
				correct: response["LISTENING-META"].numbercorrect,
				total: total_temp,
			};

			setListeningResult(true);
		}

		if (response["WRITING-NAME"]) {
			const writingQuestion = await fetchAPI(
				`/writing/get?id=placement-write${
					testID !== "placement-1" ? "-" + testID : ""
				}`
			);

			setContent(
				`<h4 class="center bold">Writing Result</h4>
                <p><em>Student: </em><span class="red-text bold">${
									response["NAME"].split(":")[0]
								}</span></p>
								<p><em>Test name: </em>
								<span class="red-text bold">${testname}</span>
								</p>
                <p><em>Band result: </em><span class="red-text bold"> band </span></p>
					${
						writingQuestion.data["task1"]
					}<div class='red-text italic'>Student's answer</div>
					${response["WRITING-TASK1"] ? response["WRITING-TASK1"] : ""} <p><br></p>
					${
						writingQuestion.data["task2"]
					}  <div class='red-text italic'>Student's answer</div>
					${response["WRITING-TASK2"] ? response["WRITING-TASK2"] : ""}`
			);

			setWritingResult(true);
		}
		if (
			!(
				response["READING-KEY"] ||
				response["LISTENING-KEY"] ||
				response["WRITING-NAME"]
			)
		)
			setMessage("Không có dữ liệu");
	}

	async function getResult(e, type, id, testname) {
		e.preventDefault();

		document.getElementById("placement-result").style.display = "none";

		ReadingResult.length = 0;
		ListeningResult.length = 0;
		setMessage("");
		const emailToFetch = e.target[0].value;
		setLoading(true);
		var response;
		try {
			if (type == "placement")
				response = await fetchAPI(
					`/students/placement?email=${emailToFetch}&id=${id}`
				);
			else {
				response = await fetchAPI(`/test/${type}/${id}?email=${emailToFetch}`);
			}
		} catch (error) {
			setMessage(error.message);
			setLoading(false);
			return;
		}

		if (response.data.message) {
			setMessage(response.data.message);
			setLoading(false);
			return;
		}
		document.getElementById("placement-result").style.display = "block";
		setLoading(false);
		setReadingResult(false);
		setListeningResult(false);
		setWritingResult(false);

		setTestName(testname);

		response = response.data;

		mapResult(response, testname, id);
	}

	return wakeup ? (
		<div className="row p-2 justify-content-around ">
			<div className="col l6 m12 mt-5 box-shadow h-100">
				<div>
					{superAdmin && <SuperAdmin fetchAPI={fetchAPI} />}
					<GenId fetchAPI={fetchAPI} />
					<div className="mt-5" id="gen-result"></div>
					<ul style={{ marginTop: "3em" }} />
					<GenResult
						fetchAPI={fetchAPI}
						getResult={getResult}
						loading={loading}
					/>
					{message && (
						<div className="alert" onClose={() => setMessage()}>
							{message}
						</div>
					)}
				</div>
			</div>
			{/* Student result right */}
			<div className="col l6 m12">
				<div id="placement-result">
					{(readingResult || writingResult || listeningResult) && (
						<h4 className="center mt-5 mb-5 kq-hs">Kết quả của học sinh</h4>
					)}

					<ul style={{ marginTop: "3em" }} />

					{listeningResult && (
						<div className="result-print-area ">
							<div ref={listeningRef} className="listening-result-wrapper">
								<h4 className="center bold">Listening Result</h4>
								<p>
									<em>Student: </em>
									<span className="red-text bold">{name}</span>
								</p>
								<p>
									<em>Test name: </em>
									<span className="red-text bold">{testName}</span>
								</p>
								<p>
									<em>Correct: </em>
									<span className="red-text bold">
										{metaResult["listening-meta"]
											? metaResult["listening-meta"].correct
											: 0}
									</span>
								</p>
								<p>
									<em>Band: </em>
									<span className="red-text bold">
										{bandCal({
											correct: metaResult["listening-meta"]
												? metaResult["listening-meta"].correct
												: 0,
											total: metaResult["listening-meta"]
												? metaResult["listening-meta"].total
												: 0,
										})}
									</span>
								</p>
								<ResultTable response={ListeningResult} />
							</div>
							<ReactToPrint
								trigger={() => (
									<button className="waves-effect waves-light btn m-10-full p-lr-full-5 main-theme-back">
										Print Listening
									</button>
								)}
								content={() => listeningRef.current}
								documentTitle={"Listening - Placement test -" + name}
							/>

							<ul style={{ marginTop: "3em" }} />
							<hr />
							<ul style={{ marginTop: "3em" }} />
						</div>
					)}

					{readingResult && (
						<div className="result-print-area ">
							<div className="reading-result-wrapper" ref={readingRef}>
								<h4 className="center bold">Reading Result</h4>
								<p>
									<em>Student: </em>
									<span className="red-text bold">{name}</span>
								</p>
								<p>
									<em>Test name: </em>
									<span className="red-text bold">{testName}</span>
								</p>
								<p>
									<em>Correct: </em>
									<span className="red-text bold">
										{metaResult["reading-meta"]
											? metaResult["reading-meta"].correct
											: 0}
									</span>
								</p>
								<p>
									<em>Band: </em>
									<span className="red-text bold">
										{bandCal({
											correct: metaResult["reading-meta"]
												? metaResult["reading-meta"].correct
												: 0,
											total: metaResult["reading-meta"]
												? metaResult["reading-meta"].total
												: 0,
										})}
									</span>
								</p>
								<ResultTable response={ReadingResult} />
							</div>
							<div className="d-flex justify-content-center w-100 ">
								{/* <button onClick={hideEvidence} className="waves-effect waves-light btn m-10-full p-lr-full-5 main-theme-back" >Đóng/Mở Evidence</button> */}
								<ReactToPrint
									trigger={() => (
										<button className="waves-effect waves-light btn m-10-full p-lr-full-5 main-theme-back">
											Print Reading
										</button>
									)}
									content={() => readingRef.current}
									documentTitle={"Reading - Placement test -" + name}
								/>
							</div>

							<ul style={{ marginTop: "3em" }} />
							<hr />
							<ul style={{ marginTop: "3em" }} />
						</div>
					)}

					{writingResult && (
						<div className="result-print-area">
							<div
								className="writing-result-wrapper w-100"
								ref={writingWrapperRef}
							>
								<JoditEditor
									ref={editor}
									config={config}
									value={content}
									tabIndex={1} // tabIndex of textarea
									onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
								/>
							</div>

							<ReactToPrint
								trigger={() => (
									<button className="waves-effect waves-light btn m-10-full p-lr-full-5 main-theme-back">
										Print Writing
									</button>
								)}
								content={() =>
									writingWrapperRef.current.firstChild.childNodes[1]
								}
								documentTitle={"Writing - Placement test -" + name}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	) : (
		// Loading when wakeup
		<div className="container signup-container">
			<div className="row m-10-full">
				<div className="d-flex justify-content-center col s12">
					<div className="preloader-wrapper big active center">
						<div class="spinner-layer spinner-red">
							<div class="circle-clipper left">
								<div class="circle"></div>
							</div>
							<div class="gap-patch">
								<div class="circle"></div>
							</div>
							<div class="circle-clipper right">
								<div class="circle"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminHome;
