import { useContext, useEffect, useState } from "react";
import { TestContext } from "../Context/TestContext";
import { Link } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const ReviewTest = (props) => {
	const { currentReview } = useContext(TestContext);
	const [key, setKey] = useState([]);
	const [answer, setAnswer] = useState([]);
	const [numques, setNumQues] = useState(0);
	const [time_total, setTimeTotal] = useState(0);

	var ques = 0;
	const mapAnswer = () => {
		return answer.map((ans, idx) => {
			let key_display = key[idx][1].join(" / ");
			let validate = ans[2] > 0 ? "user-correct" : "user-false";
			let mark = ans[2] > 0 ? "correct-answer" : "false-answer";
			ques += ans[0];

			return ans[0] === 1 ? (
				<li className="list-answer-item" key={idx}>
					<span className="number"> {ques} </span>
					<span className="sys-answer">{key_display} : </span>
					<span className={"user-answer " + validate}>
						{ans[1]} <em className={mark}></em>
					</span>
				</li>
			) : (
				<li class="compound-sentence list-answer-item" key={idx}>
					<span class="number">
						{" "}
						{ques - ans[0] + 1} <em></em> {ques}
					</span>
					<span class="sys-answer">{key_display}</span>
					<span class={"user-answer " + validate}>
						: {"(Correct " + ans[2] + "/" + ans[0] + ") " + [...ans[1]].join()}
					</span>
					<em className={mark}></em>
				</li>
			);
		});
	};

	useEffect(() => {
		setKey(JSON.parse(currentReview[0]));
		setAnswer(JSON.parse(currentReview[1]));
		setTimeTotal(currentReview[2].type === "Reading" ? 60 : 40);
	}, []);

	useEffect(() => {
		if (answer) {
			let ques = 0;
			answer.map((ans, idx) => {
				ques += ans[0];
				if (idx === answer.length - 1) setNumQues(ques);
			});
		}
	}, [answer]);

	function Example(props) {
		return (
			<div>
				<div style={{ marginTop: 30, display: "flex", paddingLeft: props.pd }}>
					<div style={{ width: props.size, paddingRight: 30 }}>
						{props.children}
					</div>
					<div style={{ width: "80%" }}>
						<h5 style={{ color: "green", fontWeight: "bold" }}>
							{props.label}
						</h5>
						<p>{props.description}</p>
					</div>
				</div>
			</div>
		);
	}

	return currentReview ? (
		<div className="row reading-result">
			<div className="user-box ">
				<Example label="Correct" size="200px" pd="0px">
					<CircularProgressbar
						value={Math.ceil(currentReview[2].numbercorrect / (numques / 100))}
						text={
							<tspan
								dx={-11}
								dy={6}
							>{`${currentReview[2].numbercorrect}`}</tspan>
						}
						background
						backgroundPadding={6}
						styles={buildStyles({
							backgroundColor: "#ca0e0e",
							textColor: "#fff",
							pathColor: "#fff",
							trailColor: "transparent",
							textSize: "20px",
						})}
					/>
				</Example>
				<p></p>
				<strong>
					{
						<span className="main-text-color">
							Correct {currentReview[2].numbercorrect} / {numques}
						</span>
					}
				</strong>
				<hr style={{ border: "1px solid #ddd" }} />
				<Example label="Time Spent" size="150px" pd="1.5em">
					<CircularProgressbar
						value={Math.ceil(currentReview[2].elapsed / 36)}
						text={
							<tspan dx={-16} dy={6}>{`${Math.ceil(
								currentReview[2].elapsed / 36
							)}%`}</tspan>
						}
						background
						backgroundPadding={6}
						styles={buildStyles({
							backgroundColor: "#ca0e0e",
							textColor: "#fff",
							pathColor: "#fff",
							trailColor: "transparent",
							textSize: "20px",
						})}
					/>
				</Example>
				<p></p>{" "}
				<strong>
					{
						<span className="main-text-color">
							Time Spent:{" "}
							{Math.floor(currentReview[2].elapsed / time_total).toLocaleString(
								"en-US",
								{
									minimumIntegerDigits: 2,
									useGrouping: false,
								}
							)}
							:
							{(currentReview[2].elapsed % time_total).toLocaleString("en-US", {
								minimumIntegerDigits: 2,
								useGrouping: false,
							})}
							<em> ({time_total}:00)</em>
						</span>
					}
				</strong>
				<div className="box-caption box-caption-margin">
					<p style={{ color: "green", fontWeight: "bold" }}>
						<span className="bc-icon icon-lego"></span> {currentReview[2].type}{" "}
						Answers
					</p>
				</div>
				{key && (
					<ul className="col list-answer">
						{mapAnswer()}
						<li className="italic underline">
							<Link to={"/take-test/" + props.match.params.id}>
								Taking test again?
							</Link>
						</li>
					</ul>
				)}
			</div>
		</div>
	) : (
		<div className="row">
			<div className="col s8 offset-s2 alert ok">Loading...</div>
		</div>
	);
};

export default ReviewTest;
