import React, { useState, useEffect, memo } from 'react';
import Modal from 'react-modal';
import { modalStyles } from '../styles/styles'



// User has switched back to the tab



Modal.setAppElement('#root')

const Timed = (props) => {
    // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
    var subtitle;

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';

    }
    const closeModal = () => {
        if (props.once) {
            props.submit()
            return;
        };
        if (document.querySelectorAll('[data-type]')[1]) document.querySelectorAll('[data-type]')[1].style.display = 'block'
        setIsOpen(false);
    }

    const [modalIsOpen, setIsOpen] = useState(props.isopen);
    useEffect(() => {
        setIsOpen(props.isopen);
    }, [props.isopen])

    return (
        <div style={{ maxWidth: "100px" }}>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={modalStyles}
                overlayClassName="Overlay"
            >

                <h3 ref={_subtitle => (subtitle = _subtitle)} className="bold center">TIMED.</h3>
                <div className="center bold">Please choose to submit or continue the test.</div>
                <div className="center" style={{ marginTop: '20px' }}>
                    {props.btn()}</div>
                {!props.once && <div className="center" style={{ marginTop: '20px' }}>
                    <button className='btn bold' style={{ backgroundColor: '#ec2d2d' }} onClick={closeModal}>
                        <i className="material-icons left" style={{ lineHeight: '38px' }}>restore</i>Continue</button></div>}
            </Modal>
        </div >
    );
};

export default memo(Timed);
