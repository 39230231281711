import React, { useRef, useState } from 'react'
import { useAuth } from './AuthContext';
import { Link } from 'react-router-dom';

export default function ForgotPassword() {
    const emailRef = useRef();
    const { forgotPassword } = useAuth();
    const [error, setError] = useState('');
    const [massage, setMassage] = useState('');


    async function handleSubmit(e) {
        e.preventDefault();
        // console.log(e)
        try {
            setError('')
            await forgotPassword(emailRef.current.value)
            setMassage('Check your email for further instruction.')
            e.target[1].disabled = true;

        } catch
        {
            setMassage('')
            setError("Email does not exist. Please try again")
        }

    }
    return (
        <>
            <div className="container signup-container">
                <div className="row signup">
                    <form className="col s12" onSubmit={handleSubmit}>
                        <div className="row center">
                            <h4>Forgot Password</h4>
                        </div>
                        {error && <div className="alert">
                            {error}</div>}
                        {massage && <div className="alert ok">
                            {massage}
                        </div>}
                        <div className="row signup">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">email</i>
                                <input id="email" type="email" ref={emailRef} className="validate" />
                                <label htmlFor="email">Email registered</label>
                            </div>
                        </div>
                        <button className="btn signup">Reset</button>
                    </form>
                </div>
                <div className="signup prompt">
                    Your password is reset? <Link style={{ fontStyle: 'italic' }} to="/log-in">Log in.</Link>
                </div>
            </div>
            <ul className="line-break"></ul>
        </>
    )
}
