const Section1 = () => {
    return (
        <section>
            <div className="section1">
                <div className="container">
                    <div className="row">
                        <div className="col l6 m12 center">
                            <img loading="lazy" src="https://raw.githubusercontent.com/thanhdanh27600/tfc/main/images/slidex.jpg" style={{ width: '100%' }}></img>
                        </div>
                        <div className="col l6 m12">
                            <h2>
                                <span className="bold cut">Trải nghiệm thi thật trên lớp học</span>
                            </h2>
                            <h3 className="bold"><span >Đối tác của <span className="fill"> British Council </span>
                                <br />
                            </span>
                                (Hội Đồng Anh)</h3>
                            <p style={{ fontSize: '20px', fontStyle: 'italic' }}>The Forum là một trong những địa điểm hiếm hoi có thể tổ chức thi IELTS. The Forum được cấp phép tổ chức thi IELTS mỗi tháng, và sở hữu cơ sở vật chất phòng thi hiện đại, đem lại trải nghiệm thi hoàn hảo.</p>


                        </div>
                    </div>
                </div>

            </div>

        </section>
    );
}

export default Section1;