import { useAuth } from "./AuthContext";
import { Redirect, Link } from "react-router-dom";
import { useState } from "react";

const EmailConfirm = () => {
    const { currentUser } = useAuth();
    const [massage, setMassage] = useState();
    const clickHandle = (e) => {
        if (!currentUser.email) {
            setMassage("Cannot find your email. Please use an account associated with pulic email address");
            return;
        }
        e.target.disabled = true;
        setMassage("Code has been sent to " + currentUser.email + ".Please check your inbox for further verification")
        currentUser.sendEmailVerification({
            url: "https://theforumcenter.web.app/",
        })
    }
    return (currentUser && currentUser.emailVerified) ? (<Redirect to='/' />) : (
        <div className="row">
            <div className="col s8 offset-s2" style={{ marginTop: '3em' }}>
                <div className="input-field">
                    {!massage && <div className="alert">Your account hasn't been verified yet.</div>}

                    {massage && <><div className="alert ok">
                        {massage} </div></>}
                </div>


            </div>
            <div className="col s8 offset-s2 center">
                <button className=" btn waves-effect waves-light main-theme-back" onClick={clickHandle}>Resend
                    <i className="material-icons right">email</i>
                </button>
                <span className="right" >
                    <Link style={{ marginLeft: '0px' }} to='/' className="material-icons right">refresh</Link>
                </span>
            </div>
        </div >
    );
}
export default EmailConfirm;