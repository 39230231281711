
export const config = {
    readonly: false,
    buttons: "strikethrough, brush",
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    width:1000,
}

