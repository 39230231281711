import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import handlePopState from "../helpers/popState";


const Done = () => {
    const history = useHistory();
    const clickHandle = () => {
        history.push('/my-test')
    }

    useEffect(() => {
        window.addEventListener("popstate", handlePopState);
    })


    return (
        <div className="row">
            <div className="col s8 offset-s2" style={{ marginTop: '3em' }}>
                <div className="input-field row s1">
                    <div className="alert ok">Congrats. You can go to My test for the results.</div>
                </div>
                <div className="center">
                    <button className="row s2 btn waves-effect waves-light main-theme-back bold" style={{ backgroundColor: 'red' }} onClick={clickHandle}> My test
                        <i className="material-icons right">library_books</i>
                    </button>
                </div>
            </div>
        </div >
    )
}

export default Done;