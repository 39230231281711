import React, { useEffect, useCallback, useState } from 'react'
import { useAuth } from './AuthContext';
import { Link } from 'react-router-dom';
import fb from './firebase';

const ADMIN = 1;
const OFFICE = 2;
const TEACHER = 3;

export default function Profile() {
    const { currentUser } = useAuth();
    var split;
    if (currentUser.displayName) {
        split = currentUser.displayName.split(':')
    }
    else {
        split = ".."
    }
    const [isAdmin, setIsAdmin] = useState(0)

    const getInit = useCallback(
        async () => {

            const doc = await fb.firestore().collection('staff').doc(currentUser.uid).get();
            if (!doc.exists) {
                //console.log('No such document!');
            } else {
                if (doc.data()['admin']) {
                    setIsAdmin(ADMIN);
                    return;
                }
                if (doc.data()['hocvu']) {
                    setIsAdmin(OFFICE);
                }
                if (doc.data()['teacher']) {
                    setIsAdmin(TEACHER);
                }
            }

        },
        [],
    )

    useEffect(() => {
        if (currentUser) {
            //console.log(currentUser)
            getInit();
        }
    }, [currentUser])


    return (
        <>
            <div className="container signup-container">
                <div className="row signup" style={{maxWidth: '500px'}}>
                    <div className="col s12">
                        <div className="row center">
                            <h4>Profile</h4>
                            <img style={{ width: '30%' }} src={currentUser.photoURL} alt=""></img>
                        </div>
                        <div className="row signup">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">account_box</i>
                                <input disabled value={split[0]} />
                            </div>
                            <div className="input-field col s12">
                                <i className="material-icons prefix">phone</i>
                                <input disabled value={split[1]} />
                            </div>
                            <div className="input-field col s12">
                                <i className="material-icons prefix">email</i>
                                <input disabled value={currentUser.email} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="input-field col s6">
                                <Link className="btn waves-effect main-theme-back" to="/log-out"> Log out</Link>
                            </div>
                            {(isAdmin === ADMIN || isAdmin === OFFICE) && <div className="input-field col s3 right">
                                <Link className="btn waves-effect main-theme-back right" to="/admin"> Admin</Link>
                            </div>}
                            {(isAdmin === ADMIN || isAdmin === TEACHER) && <div className="input-field col s3">
                                <Link className="btn waves-effect main-theme-back right" to="/admin-class"> Class</Link>
                            </div>}
                        </div>

                    </div>
                </div>


            </div>
            <ul className="line-break"></ul>
        </>
    )
}
