import { useContext, useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/AuthContext";
import { TestContext } from "../Context/TestContext";
import AudioPlayer from "react-h5-audio-player";
import "./custom-player.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countdown from "react-countdown";
import ButtonSubmit from "../helpers/btnSubmit";
import Timed from "../helpers/timed";
import useWindowDimensions from "../helpers/Dimension";
import { FirestoreDocument } from "@react-firebase/firestore";
import ReactHtmlParser from "react-html-parser";
import { compareMore, compareOne } from "../helpers/compareAnswer";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import Highlighter from "web-highlighter";

const MAXTIME = 40 * 60 * 1000;

const ListeningPane = ({ runMutation, id }) => {
	const history = useHistory();
	const time = useRef();
	const youtubeBtn = useRef();
	const slRef = useRef();
	const [mobile, setMobile] = useState(false);
	const { height, width } = useWindowDimensions();
	const { testInfo, isTest, setIsTest } = useContext(TestContext);
	const { currentUser } = useAuth();
	const [loading, setLoading] = useState(false);
	const [testname, setTestName] = useState("");
	const [src, setSrc] = useState("");
	const [key, setKey] = useState("");
	const [question, setQuestion] = useState();
	const [isopen, setIsOpen] = useState(false);
	const [isplay, setIsPlay] = useState(false);
	const [isYoutube, setIsYoutube] = useState(false);
	const [srcYoutube, setSrcYoutube] = useState("");
	var highlighter;
	const onPlay = (e) => {
		e.preventDefault();
		setIsPlay(true);
		time.current.start();
		if (youtubeBtn.current) youtubeBtn.current.disabled = false;
	};

	const onPause = (e) => {
		e.preventDefault();
		setIsPlay(false);
	};

	const onStartClock = () => {
		let date = new Date();
		localStorage.setItem("lis-date" + id, time.current.props.date);
		if (localStorage.getItem("lis-start" + id)) {
			localStorage.setItem(
				"lis-start" + id,
				localStorage.getItem("lis-start" + id)
			);
		} else localStorage.setItem("lis-start" + id, date.getTime());
	};

	const renderer = ({ minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			return <span>Timed!</span>;
		} else {
			// Render a countdown
			return (
				<span>
					{minutes.toLocaleString("en-US", {
						minimumIntegerDigits: 2,
						useGrouping: false,
					})}
					:
					{seconds.toLocaleString("en-US", {
						minimumIntegerDigits: 2,
						useGrouping: false,
					})}
				</span>
			);
		}
	};

	const checkWidth = () => {
		if (width < 768) {
			setMobile(true);
		} else {
			setMobile(false);
		}
	};

	const finish = () => {
		setIsOpen(true);
	};

	const initHighLightBtn = () => {
		var elems = document.querySelectorAll(".fixed-action-btn");
		console.log(
			"file: ListeningPane.js ~ line 99 ~ initHighLightBtn ~ elems",
			elems
		);
		var instances = window.M.FloatingActionButton.init(elems, {
			hoverEnabled: false,
			direction: width < 768 ? "left" : "right",
		});
		//highlighter
		elems[0].addEventListener("mouseenter", () => {
			elems[0].style.opacity = 1;
		});
		elems[0].addEventListener("mouseleave", () => {
			elems[0].style.opacity = 0.5;
		});
	};

	const turnOnHighlighter = (color) => {
		if (highlighter) {
			highlighter.stop();
		}
		localStorage.setItem("hl", "1");
		window.document.body.classList.add("highlighting");
		highlighter = new Highlighter({
			$root: window.document.getElementById("highlight-listening"),
			exceptSelectors: ["img", "input", "select"],
			wrapTag: "span",
			style: {
				className: "highlight-theforum-" + color,
			},
		});

		highlighter
			.on("selection:hover", ({ id }) => {
				// display different bg color when hover
				if (localStorage.getItem("hl") === "1")
					highlighter.addClass("highlight-cursor", id);
				window.document.body.classList.remove("highlighting");
			})
			.on("selection:click", ({ id }) => {
				// display different bg color when hover

				if (localStorage.getItem("hl") === "1") highlighter.remove(id);
			})

			.on("selection:hover-out", ({ id }) => {
				highlighter.removeClass("highlight-cursor", id);
				if (
					!window.document.body.classList.contains("highlighting") &&
					localStorage.getItem("hl") === "1"
				)
					window.document.body.classList.add("highlighting");
			})

			.on("selection:remove", ({ id }) => {
				highlighter.removeClass("highlight-cursor", id);
			});

		highlighter.run();
	};

	const turnOffHighlighter = () => {
		if (highlighter) {
			highlighter.stop();
		}
		window.document.body.classList.remove("highlighting");
		localStorage.setItem("hl", "0");
	};

	async function handleSubmit() {
		setLoading(true);
		let key_arr = JSON.parse(key);
		let current_question = 0;
		let user_answer = [];
		var numCorrect = {
			numbercorrect: 0,
		};

		key_arr.forEach((value) => {
			let current_answer = [value[0]];
			if (value[0] === 1) {
				current_question++;
				if (slRef.current.querySelector("#q-" + current_question).value)
					current_answer.push(
						slRef.current.querySelector("#q-" + current_question).value
					);
				else {
					let is_check = false;
					slRef.current
						.querySelector("#q-" + current_question)
						.querySelectorAll('[name="q-' + current_question + '"]')
						.forEach((ele) => {
							if (ele.checked) {
								current_answer.push(ele.value);
								is_check = true;
							}
						});
					if (!is_check) {
						current_answer.push("");
					}
				}
			} else {
				let temp_ans = "";
				let selector = "q";
				for (var q = 0; q < value[0]; q++) {
					current_question++;
					if (q === 0 || q === value[0] - 1) selector += "-" + current_question;
				}
				slRef.current
					.querySelector("#" + selector)
					.querySelectorAll('[name="' + selector + '"]')
					.forEach((ele) => {
						if (ele.checked) {
							temp_ans += ele.value;
						}
					});
				current_answer.push(temp_ans);
			}
			if (current_answer[0] === 1) {
				compareOne(value[1], current_answer, numCorrect);
			} else {
				compareMore(value[1], current_answer, numCorrect);
			}
			user_answer.push(current_answer);
		});
		let is_unans = false;
		user_answer.forEach((ans) => {
			if (ans[1] === "") {
				is_unans = true;
			}
		});

		if (
			is_unans &&
			!window.confirm("There is unanswered question. Do you want to proceed?")
		)
		{
			setLoading(false);
			return;
		}
			

		let date = new Date();
		let elapsed = localStorage.getItem("lis-start" + id)
			? Math.round(
					(date.getTime() - parseInt(localStorage.getItem("lis-start" + id))) /
						1000
			  )
			: 0;
		if (elapsed > 40 * 60) elapsed = 40 * 60;
		localStorage.removeItem("lis-start" + id);
		localStorage.removeItem("lis-date" + id);

		var meta = {
			type: "Listening",
			elapsed: elapsed,
			numbercorrect: numCorrect.numbercorrect,
			once: testInfo.once,
		};

		//DEV

		// console.log("Answer", user_answer)
		// console.log("Meta", meta)
		// console.log("Key", key_arr)

		//END DEV

		try {
			await runMutation({
				ClientTimestamp: Date.now(),
				ServerTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
				Name: currentUser.displayName,
				Answer: JSON.stringify(user_answer),
				Meta: JSON.stringify(meta),
				Key: key,
				Testname: testname,
			}).then((res) => {
				// console.log(res)
				history.replace("/done");
			});
		} catch (err) {
			window.alert(err);
			console.log(err);
		}
	}

	const btnSubmit = () => {
		return <ButtonSubmit handleSubmit={handleSubmit} />;
	};

	const handleYoutube = () => {
		if (
			window.confirm(
				"Only switch to Youtube just in case the audio is broken.\nDo you want to continue?"
			)
		) {
			setIsYoutube(true);
		}
	};

	useEffect(() => {
		checkWidth();
		initHighLightBtn();
		setIsTest(true);
		return () => {
			setIsTest(false);
			if (highlighter) {
				highlighter.stop();
				highlighter.dispose();
			}
			window.document.body.classList.remove("highlighting");
		};
	}, []);

	useEffect(() => {
		if (!isplay && key) {
			console.log(srcYoutube);
			toast("Click play to start the test", {
				position: "top-center",
				autoClose: 10000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			if (youtubeBtn.current) youtubeBtn.current.disabled = true;
		}
	}, [key]);

	return loading ? (
		<div className="row">
			<div className="col s8 offset-s2 alert ok">Loading...</div>
		</div>
	) : (
		<>
			{!key && (
				<div className="row">
					<div className="col s8 offset-s2 alert ok">Loading...</div>
				</div>
			)}
			{!key && (
				<FirestoreDocument path={"/listening/" + id}>
					{(d) => {
						if (d.value && d.isLoading === false) {
							setKey(d.value.key);
							setTestName(d.value.testname);
							setQuestion(d.value.question);
							setSrc(d.value.src);
							setSrcYoutube(d.value.src2);
							console.log("lis-get-ed");
						}
					}}
				</FirestoreDocument>
			)}

			<div className="container-listening">
				<Timed
					btn={btnSubmit}
					isopen={isopen}
					once={testInfo.once}
					submit={handleSubmit}
				/>
				<ToastContainer
					position="top-right"
					autoClose={10000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					limit={1}
				/>
				{src && (
					<div className="testing listening-page">
						<div className="lp-left" ref={slRef}>
							<div className="split-left">
								<div className="player-listening player-test">
									<div className="container-listening">
										<div className="player player-fixed">
											{!isYoutube && (
												<AudioPlayer
													src={src}
													onPlay={onPlay}
													customControlsSection={[
														"MAIN_CONTROLS",
														"VOLUME_CONTROLS",

														<button
															ref={youtubeBtn}
															className="btn main-theme-back"
															onClick={handleYoutube}
														>
															Youtube
														</button>,
													]}
													layout={"horizontal-reverse"}
													onPause={onPause}
													crossOrigin={undefined}
													// other props here
												/>
											)}
											{isYoutube && (
												<div className="w-100">
													{srcYoutube ? (
														<iframe
															style={{
																width: "inherit",
																height: "98px",
															}}
															src={srcYoutube}
															title="YouTube video player"
															frameborder="0"
															allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
														></iframe>
													) : (
														<div className="alert">
															This audio is not available in Youtube
														</div>
													)}
												</div>
											)}
										</div>
									</div>
								</div>
								<div id="highlight-listening">
									{question && ReactHtmlParser(question)}
								</div>
							</div>
						</div>
						<div className="lp-right lis-clock">
							<div className="container"></div>
							<div className="qp-afix affix">
								<div className="listening-footer">
									<div className="self-center mgl-5p lis-clock">
										<Countdown
											date={
												localStorage.getItem("lis-date" + id) &&
												parseInt(localStorage.getItem("lis-date" + id)) >
													Date.now()
													? parseInt(localStorage.getItem("lis-date" + id))
													: Date.now() + MAXTIME
											}
											daysInHours={true}
											ref={time}
											onComplete={finish}
											onStart={onStartClock}
											renderer={renderer}
											autoStart={false}
										/>
									</div>
									<div className="self-center mgl-5p">{btnSubmit()}</div>
								</div>
							</div>
						</div>
						<div className="clearfix hide-on-small-only"></div>
					</div>
				)}
				<div
					class="highlight-btn fixed-action-btn"
					style={{ bottom: width > 1024 ? "3vh" : "15vh" }}
				>
					<a class="btn-floating btn-large red">
						<i class="large material-icons">auto_fix_normal</i>
					</a>
					<ul>
						<li>
							<a
								class="btn-floating orange lighten-1"
								onClick={() => turnOnHighlighter("orange")}
							/>
						</li>
						<li>
							<a
								class="btn-floating yellow lighten-3"
								onClick={() => turnOnHighlighter("yellow")}
							/>
						</li>
						<li>
							<a
								class="btn-floating green lighten-2"
								onClick={() => turnOnHighlighter("green")}
							/>
						</li>
						<li>
							<a
								class="btn-floating purple lighten-1"
								onClick={() => turnOnHighlighter("purple")}
							/>
						</li>
						<li>
							<a
								class="btn-floating cyan lighten-2"
								onClick={() => turnOnHighlighter("cyan")}
							/>
						</li>
						<li>
							<a
								class="btn-floating red lighten-1"
								onClick={() => turnOnHighlighter("red")}
							/>
						</li>
						<li>
							<a class="btn-floating grey" onClick={turnOffHighlighter}>
								<i class="large material-icons">auto_fix_off</i>
							</a>{" "}
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};

export default ListeningPane;
